import React from "react";
import AboutUsImg from "../../assets/about-us.jpg";
import { siteDarkGreen } from "../../providers/Theme";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import {
  BodyText,
  StyledBold,
  StyledJumbotron
} from "../shared.styled.components";
import { StyledImage, StyledLink, StyledContainer } from "./styled.components";
import AboutUsPageNav from "../../components/AboutUsPageNav";

const CDC_REF = "https://www.cdc.gov/school-health-conditions/food-allergies/";

const AboutUs: React.FC = () => {
  return (
    <>
      <Row>
        <Col className="d-flex flex-wrap align-items-center" lg={4} sm={12}>
          <StyledImage fluid src={AboutUsImg} />
        </Col>
        <Col className="my-auto" lg={8} sm={12}>
          <StyledContainer>
            <h1>
              <b>Kyah Rayne</b>
            </h1>
            <BodyText>
              Kyah Rayne was born March 17, 1997. The name Kyah, in its African
              root, means "diamond in the sky." In Hindi, Kyah means Aura/Life
              changing and her middle name, Rayne, means "Queen." Keshet, her
              Hebrew name means "rainbow." She was and will always be a Life
              Changing Queen. Kyah was one of those unique bright lights in the
              lives of everyone she knew. She had a quiet, self-assured
              presence. She didn't need to be the center of attention, but she
              took her place there with ease and assurance. She danced her way
              through life. She required the truth from her family, friends, and
              herself. She was a beauty inside and out. She was and will always
              be my best friend and teacher. She knew who she was and continues
              to show me how to be the best I can be. To live, to love, to tell
              the truth, to dance, to be of service. She was the smartest person
              I've ever met. Kyah Rayne is my daughter.
            </BodyText>

            <StyledBold>
              <BodyText color={siteDarkGreen}>
                Read our story published by{" "}
                <StyledLink
                  href="https://www.allergicliving.com/thisallergiclife/in-mexico-we-lost-my-girl-to-a-taste-of-peanut/"
                  target="_blank"
                >
                  Allergic Living
                </StyledLink>{" "}
                and{" "}
                <StyledLink
                  href="https://issuu.com/frontdoorsnews/docs/frd055_fd_nov_dec_issuu"
                  target="_blank"
                >
                  Frontdoors Media
                </StyledLink>
              </BodyText>
            </StyledBold>
          </StyledContainer>
        </Col>
      </Row>

      <StyledJumbotron backgroundColor={siteDarkGreen}>
        <Container className="text-center">
          <h2>WHY WE ARE HERE</h2>

          <BodyText>
            The prevalence of{" "}
            <StyledLink href={CDC_REF} target="_blank">
              food allergies among children has been on the rise
            </StyledLink>
            , increasing by 50% from 2007 to 2021 (Zablotsky 2023). Food
            allergies are a leading cause of anaphylaxis, a severe,
            life-threatening allergic reaction that requires an epinephrine
            injection (Mayo Clinic, 2023).
          </BodyText>
          <BodyText>
            Studies show that 16%-18% of children with food allergies have had a
            reaction from accidentally eating food allergens while at school. In
            addition, 25% of the severe and potentially life-threatening
            reactions reported at schools happened to children with NO previous
            diagnosis of food allergy (Fortner 2017).
          </BodyText>

          <BodyText>
            While no cure exists for food allergies, research has shown that
            early exposure, rigorous avoidance practices, immediate use of
            epinephrine, and comprehensive education in public settings can help
            manage and reduce the occurrence of anaphylaxis and allergic
            reactions. Preventive strategies and early intervention are key to
            reducing food allergy risks and fatalities.
          </BodyText>

          <BodyText>
            The Kyah Rayne Foundation is striving to help Early Care and
            Education programs (ECE) prevent allergic reactions and respond to
            food allergy emergencies, including anaphylaxis.
          </BodyText>

          <BodyText>
            Our vision is a world without food allergy fatalities, where
            schools, early childhood programs, and restaurants are empowered to
            prevent and respond to food allergy emergencies.
          </BodyText>

          <BodyText>
            Our mission is to create and oversee comprehensive emergency plans
            that address the prevention, preparedness, response, and recovery
            for food allergy and anaphylactic emergencies.
          </BodyText>
        </Container>

        <Container>
          <h4 className="text-center">References</h4>

          <BodyText>
            Centers for Disease Control and Prevention. Food allergies.
            Available from: {CDC_REF}
          </BodyText>

          <BodyText>
            Zablotsky B, Black LI, Akinbami LJ. Diagnosed allergic conditions in
            children aged 0-17 years: United States, 2021. NCHS data brief. 2023
            Jan;(459):1-8. PMID: 36700870. Available from:
            https://www.cdc.gov/nchs/products/databriefs/db459.htm
          </BodyText>

          <BodyText>
            Mayo Clinic. Anaphylaxis. Available from:
            https://www.mayoclinic.org/diseases-conditions/anaphylaxis/symptoms-causes/syc-
            20351468. Published September 22, 2023.
          </BodyText>

          <BodyText>
            Fortner P. Food allergies: Supporting safety in the school
            environment. USDA. May 18, 2017. Available from:
            https://www.usda.gov/media/blog/2017/05/18/food-allergies-supporting-safety-school-
            environment#:~:text=More%20than%2015%20percent%20of%20school%2Daged%20children,unknown%20at%20the%20time
            %20of%20the%20reaction.
          </BodyText>
        </Container>
      </StyledJumbotron>

      <AboutUsPageNav currentPage="about-us" />
    </>
  );
};

export default AboutUs;
