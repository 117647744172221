import styled from "styled-components";
import { BODY_FONT_SIZE } from "../shared.styled.components";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

export const StyledImage = styled(Image)`
  height: 475px;

  object-fit: cover;

  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.lg`
    margin: 0;
  `}
`;

export const StyledLink = styled.a`
  font-size: ${BODY_FONT_SIZE};
  color: inherit;
  text-decoration: underline;

  &:hover {
    color: inherit;
  }
`;

export const StyledContainer = styled(Container)`
  padding-right: 0;
  ${({ theme }) => theme.xl`
    padding-right: 3rem;
  `}
`;
