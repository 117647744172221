import React, { useState } from "react";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import PageTitleBanner from "../../components/PageTitleBanner";
import InTheNewsBanner from "../../assets/in-the-news.png";
import NewsItem from "../../components/NewsItem";
import { PageContainer } from "../shared.styled.components";
import JunePressRelease from "../../assets/KRF-Press-Release_June 22-2021.pdf";
import LisaAceWashington from "../../assets/news-items/lisa-ace-washington.jpg";
import { InTheNewsProps } from "../../components/NewsItem/NewsItem";
import { goToExternalUrl } from "../../utils";
import Pagination from "react-bootstrap/Pagination";
import { StyledPagination } from "./styled.components";
import AboutUsPageNav from "../../components/AboutUsPageNav";

interface ActionProps {
  path: string;
  type: "external" | "internal";
}

type NewsItems = Pick<InTheNewsProps, "title" | "text" | "imageProps"> & {
  actionOptions: ActionProps;
};

const newsItems: NewsItems[] = [
  {
    title:
      "New Rhode Island Law Will Require No-cost Coverage for Epinephrine Injectors",
    text: "Health insurers will be required to provide prescription coverage for at least one twin pack of epinephrine injectors each year for all policies issued or renewed after Jan. 1, 2025, under a new Rhode Island law.",
    actionOptions: {
      type: "external",
      path: "https://pbn.com/new-r-i-law-will-require-no-cost-coverage-for-epinephrine-injectors/"
    }
  },
  {
    title: "Know Your Rights: Public Access to Epinephrine",
    text: "FARE released a list of state statutes that allow entities to stock undesignated stock epinephrine for use in case of an emergency.",
    actionOptions: {
      type: "external",
      path: "https://www.foodallergy.org/our-initiatives/advocacy/know-your-rights/public-access-epinephrine"
    }
  },
  {
    title:
      "Minnesota House of Representatives Propose a Bill to Cap Epinephrine Auto-Injectors Co-pays at $25",
    text: "HF348 would require health plans to limit patient co-pays to no more than $25 per one-month supply for prescription drugs used to treat chronic diseases such as asthma, diabetes, and allergies requiring the use of epinephrine auto-injectors, or EpiPens.",
    actionOptions: {
      type: "external",
      path: "https://www.house.mn.gov/sessiondaily/Story/17655#:~:text=HF348%20would%20require%20health%20plans%20to%20limit%20patient,requiring%20the%20use%20of%20epinephrine%20auto-injectors%2C%20or%20EpiPens"
    }
  },
  {
    title:
      "Bryn Pharma Publishes Clinical Study for Epinephrine Nasal Spray NDS1C",
    text: "Bryn Pharma, LLC, a privately held pharmaceutical company dedicated to finding a novel and convenient way for patients and caregivers to treat anaphylaxis, announced data from the Company’s pivotal clinical study for its investigational epinephrine nasal spray (ENS), NDS1C, have been published online by the Journal of Allergy and Clinical Immunology (JACI): Global.",
    actionOptions: {
      type: "external",
      path: "https://www.brynpharma.com/docs/Bryn-Announces-Publication-of-NDS1C-Data.pdf"
    }
  },
  {
    title: "Pfizer Offers $345 Million to Settle Out of EpiPen Pricing Lawsuit",
    text: "Pfizer resolves its part of a class action lawsuit regarding the pricing of epinephrine auto-injectors.",
    actionOptions: {
      type: "external",
      path: "https://www.biospace.com/article/pfizer-offers-345-million-to-settle-out-of-epipen-pricing-lawsuit/"
    }
  },
  {
    title: "Kansas City Enacts Elijah's Law/City Ordinance",
    text: "Starting January 1, 2024, childcare providers will need to ensure they have emergency protocols and epinephrine auto-injectors inspired by the tragic loss of 3-year-old Elijah Silvera.",
    actionOptions: {
      type: "external",
      path: "https://www.elijahalavifoundation.org/post/kansas-city-enacts-elijah-s-law-city-ordinance?utm_campaign=de05059a-f05e-4862-aafb-031d948fbc9e&utm_source=so&utm_medium=mail&cid=69a0eace-0939-41da-a7d3-2ff28c29633d"
    }
  },
  {
    title:
      "Table Talk Podcast Featuring Founder of the Kyah Rayne Foundation, Lisa Cohen",
    text: `Host of the Table Talk Podcast, Joni Rampolla, interviews Lisa Cohen of the Kyah Rayne Foundation to learn more about the mission of this "allergy-mom"in the podcast's food allergy series.`,
    actionOptions: {
      type: "external",
      path: "https://www.youtube.com/watch?v=HdJk390FDi4&ab_channel=TheGIANTCompanyDietitians"
    }
  },
  {
    title: "Join the Congressional Food Allergy Research Caucus (House Only)",
    text: "Co-chaired by Reps. Doris Matsuri (D-CA-07) and Patrick McHenry (R-NC-10), this bipartisan caucus, created in 2021 is dedicated to raising greater awareness about food allergies an increasing federal investment in food allergy research.",
    actionOptions: {
      type: "external",
      path: "https://matsui.house.gov/media/press-releases/matsui-mchenry-khanna-and-cline-launch-bipartisan-food-allergy-research-caucus#:~:text=The%20Congressional%20Food%20Allergy%20Research%20Caucus%20will%20provide,policies%20to%20help%20benefit%20the%20food%20allergy%20community"
    }
  },
  {
    title:
      "Kyah Rayne Foundation Founder and FARE ambassador, Lisa Cohen, goes to Washington,D.C.",
    text: "KRF's Founder, Lisa Cohen, went to D.C. to advocate for better legislation for the 33 million Americans with life- threatening food allergies as a Food Allergy Research and Education Ambassador for this year's F.A.R.E. Courage at Congress.",
    actionOptions: {
      type: "external",
      path: "https://www.foodallergy.org/our-initiatives/advocacy/food-allergy-issues/courage-congress"
    },
    imageProps: {
      src: LisaAceWashington,
      orientation: "left"
    }
  },
  {
    title: "A Mother's Love (November/December 2022 Frontdoors Magazine)",
    text: "KRF's Founder, Lisa Cohen was interviewed for a Charity Spotlight in Frontdoors Magazine. Click the following link to access the November/December 2022 digital issue and find the feature A Mother's Love on pages 91-93.",
    actionOptions: {
      type: "external",
      path: "https://issuu.com/frontdoorsnews/docs/frd055_fd_nov_dec_issuu"
    }
  },
  {
    title: "In Mexico, We Lost My Girl to a Taste of Peanut (Allergic Living)",
    text: "Lisa shared the heartbreaking story of her daughter's fatal anaphylaxis while on vacation. In the wake of the tragic loss of her daughter, she has made allergy awareness and prevention her life's work. Read her article in This Allergic Life by Allergic Living.",
    actionOptions: {
      type: "external",
      path: "https://www.allergicliving.com/thisallergiclife/in-mexico-we-lost-my-girl-to-a-taste-of-peanut/"
    }
  },
  {
    title:
      "Amendments to Rule-making Procedures on A.A.C. R7-2-809 and A.A.C.R7-2-810",
    text: "On August 27, 2021, KRF's Founder, Lisa Cohen presented a public comment at an Arizona State Board of Education Board Meeting regarding amendments to the rule-making procedures of epinephrine auto-injector (EAI) administration.",
    actionOptions: {
      type: "internal",
      path: "/amendments"
    }
  },
  {
    title:
      "Excess Epinephrine Auto-Injectors Become Available For Schools As COVID-19 Vaccination PODs Shut Down",
    text: "Schools in Arizona can now receive FREE, life-saving EAIs for the first time. To read our press release, click the link below.",
    actionOptions: {
      path: JunePressRelease,
      type: "external"
    }
  },
  {
    title: "Kyah's EPICourse: Continuing Education Units",
    text: "Kyah's EPICourse has also been recently approved for RD and CHES Continuing Education Credits! We are excited to provide this additional benefit to health professionals who complete our course.",
    actionOptions: {
      path: "https://moodle.publichealth.arizona.edu/enrol/index.php?id=495",
      type: "external"
    }
  }
];

const InTheNews: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = newsItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(newsItems.length / itemsPerPage);

  const customRouter = useCustomRouter();

  const goToInternalUrl = (path: string) => {
    customRouter.append(path);
  };

  const newsItemAction = (actionProps: ActionProps) => {
    if (actionProps.type === "external") {
      goToExternalUrl(actionProps.path);
    } else {
      goToInternalUrl(actionProps.path);
    }
  };

  return (
    <>
      <PageTitleBanner title="In the News" imageSrc={InTheNewsBanner} />

      <PageContainer className="pt-4">
        {currentItems.map((newsItem, idx) => (
          <NewsItem
            {...newsItem}
            action={() => newsItemAction(newsItem.actionOptions)}
          />
        ))}
        <StyledPagination size="sm" className="justify-content-center">
          <Pagination.Prev
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          />

          {Array.from({ length: totalPages }, (_, i) => i + 1).map(
            pageNumber => (
              <Pagination.Item
                key={pageNumber}
                active={pageNumber === currentPage}
                onClick={() => setCurrentPage(pageNumber)}
              >
                {pageNumber}
              </Pagination.Item>
            )
          )}

          <Pagination.Next
            onClick={() =>
              setCurrentPage(prev => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          />
        </StyledPagination>

        <AboutUsPageNav currentPage="in-the-news" />
      </PageContainer>
    </>
  );
};

export default InTheNews;
