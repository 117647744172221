export const INSTAGRAM_URL = "https://www.instagram.com/kyahraynefoundation/";
export const NATIONAL_EPICOURSE_SCHOOLS_LINK =
  "https://www.kyahsepicourse.com/course/view.php?id=5";
export const AZ_EPICOURSE_SCHOOLS_LINK =
  "https://moodle.publichealth.arizona.edu/course/view.php?id=532";
export const EPICOURSE_FOOD_SERVICE_LINK =
  "https://www.kyahsepicourse.com/course/view.php?id=3";
export const SUBSCRIBE_TO_NEWSLETTER_LINK =
  "https://kyahraynefoundation.us1.list-manage.com/subscribe?u=69716092fda5b8ecfebe0494e&id=ff12db3551";

export const NationalEPICourseForSchoolsEnrollmentVideo =
  "https://player.vimeo.com/video/1020302165";

export const MARICOPA_SSMP_ENROLLMENT_SITE =
  "https://www.maricopa.gov/5038/Rescue-Medication-Surveillance";
