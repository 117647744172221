import React, { useRef } from "react";
import PageTitleBanner from "../../components/PageTitleBanner";
import ThumbnailCard from "../../components/ThumbnailCard";
import { ThumbnailCardProps } from "../../components/ThumbnailCard/ThumbnailCard";
import FoodAllergy101Thumbnail from "../../assets/food-allergy-101.png";
import TriggerFoodsThumbnail from "../../assets/trigger-foods-thumnail.png";
import FoodAllergyRisksThumbnail from "../../assets/food-allergy-risks-thumbnail.png";
import AnaphylaxisThumbnail from "../../assets/anaphylaxis-thumbnail.png";
import EpinephrineThumbnail from "../../assets/epinephrine-thumbnail.png";
import FactsAndFiguresThumbnail from "../../assets/facts-and-figures-thumbnail.png";
import FoodAndAllergyBasicsBanner from "../../assets/food-allergy-basics-banner.png";
import { siteDarkBlue } from "../../providers/Theme";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { StyledContainer, StyledRow, NavText } from "./styled.components";

interface CardConfig extends ThumbnailCardProps {
  ref: React.MutableRefObject<any>;
}

const FoodAndAllergyBasics: React.FC = () => {
  const cardConfigs: CardConfig[] = [
    {
      src: FoodAllergy101Thumbnail,
      title: "Food Allergy 101",
      path: "/food-allergy-101",
      content: "What is a food allergy? Learn the basics.",
      ref: useRef()
    },
    {
      src: FactsAndFiguresThumbnail,
      title: "Facts and Figures",
      path: "/facts-and-figures",
      content:
        "Did you know 32 million Americans live with food allergies? Click to view more up-to-date data and information on food allergies.",
      ref: useRef()
    },
    {
      src: TriggerFoodsThumbnail,
      title: "Trigger Foods",
      path: "/trigger-foods",
      content:
        "Trigger foods are particular foods or substances in a food that can cause a food allergy. According to the U.S Food and Drug Administration, the 'Big 9' trigger foods are: milk, eggs, fish, shellfish, tree nuts, peanuts, wheat, soybeans and sesame.",
      ref: useRef()
    },
    {
      src: FoodAllergyRisksThumbnail,
      title: "Food Allergy Risks",
      path: "/food-allergy-risks",
      content:
        "There are many risks for food allergies. Every food allergy can potentially turn into anaphylaxis.",
      ref: useRef()
    },
    {
      src: AnaphylaxisThumbnail,
      title: "Anaphylaxis",
      path: "/anaphylaxis",
      content:
        "Anaphylaxis (pronounced an-uh-fil-LAX-is) is a severe, potentially life-threatening allergic reaction. It is a medical condition  occurring  in  allergic  individuals  after exposure  to  triggers such  as  certain  foods,  medicine, latex,  or  an  insect  sting.",
      ref: useRef()
    },
    {
      src: EpinephrineThumbnail,
      title: "Epinephrine",
      path: "/epinephrine",
      content:
        "Epinephrine is the only way to save someone experiencing a severe allergic reaction. It is safe, and easy to use. Always remember: Epi First, Epi Fast.",
      ref: useRef()
    }
  ];

  const scrollTo = (ref: React.MutableRefObject<HTMLDivElement>) => {
    ref.current.scrollIntoView();
  };

  return (
    <>
      <PageTitleBanner
        title="Food Allergy Basics"
        content="Learn more about food allergies. This section provides a brief introduction to food allergy key statistics and information, severe allergic reactions, and how to treat anaphylaxis."
        imageSrc={FoodAndAllergyBasicsBanner}
      />
      <Container className="mb-4">
        <Row>
          <Col className="d-inline-flex flex-wrap justify-content-center">
            <NavText bold>Jump to: </NavText>
            {cardConfigs.map((config, index) => (
              <React.Fragment key={`nav-text-${index}`}>
                <NavText
                  bold
                  isLink
                  className="px-3"
                  color={siteDarkBlue}
                  onClick={() => scrollTo(config.ref)}
                >
                  {config.title}
                </NavText>
                {index !== cardConfigs.length - 1 && (
                  <NavText className="px-2"> |</NavText>
                )}
              </React.Fragment>
            ))}
          </Col>
        </Row>
      </Container>
      <StyledRow className="pt-5">
        <StyledContainer>
          {cardConfigs.map((config, index) => (
            <div key={`thumbnail-card-${index}`} ref={config.ref}>
              <ThumbnailCard
                src={config.src}
                title={config.title}
                path={config.path}
                content={config.content}
                reverse={index % 2 === 1}
              />
            </div>
          ))}
        </StyledContainer>
      </StyledRow>
    </>
  );
};

export default FoodAndAllergyBasics;
