import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { VideoContainer } from "./styled.components";
import { PageContainer, Header, BodyText } from "../shared.styled.components";

const Anaphylaxis: React.FC = () => {
  return (
    <PageContainer>
      <Header>Anaphylaxis</Header>

      <Container fluid="md">
        <Row className="justify-content-md-center my-5">
          <Col>
            <VideoContainer>
              <iframe
                className="embed-responsive-item"
                src="https://player.vimeo.com/video/748510991?h=b58523b834"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
              />
            </VideoContainer>
          </Col>
        </Row>
      </Container>

      <BodyText>
        <b>Anaphylaxis</b> (pronounced an-uh-fil-LAX-is) is a severe,
        potentially life-threatening allergic reaction. It occurs in individuals
        who are allergic to triggers such as certain foods, medications, latex,
        or insect stings. Symptoms can affect multiple areas of the body,
        including breathing and blood circulation.
      </BodyText>

      <BodyText>
        Anaphylaxis is a serious condition that, if not treated quickly with{" "}
        <b>epinephrine</b>, can lead to death within minutes—sometimes as
        quickly as 30 minutes.
      </BodyText>

      <BodyText>
        The <b>early administration of epinephrine</b>, whether through an
        auto-injector, nasal spray, or other prescribed methods, is the primary
        and most effective treatment for anaphylaxis.
      </BodyText>
    </PageContainer>
  );
};

export default Anaphylaxis;
