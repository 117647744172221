import styled from "styled-components";
import { BODY_FONT_SIZE } from "../../pages/shared.styled.components";

import Card from "react-bootstrap/Card";

export const StyledCard = styled(Card).attrs({
  className: "mb-5 flex-fill m-2 d-flex flex-column"
})`
  min-width: 300px;
  max-width: 350px;
`;

export const CardText = styled(Card.Text).attrs({ className: "mb-2" })`
  font-size: ${BODY_FONT_SIZE};
`;

export const CardTitle = styled(Card.Title)`
  font-size: ${BODY_FONT_SIZE};
  font-weight: bold;
`;
