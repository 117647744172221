import React from "react";
import {
  BodyText,
  StyledJumbotron,
  LightButton,
  JumbotronHeader,
  JumbotronSubHeader
} from "../../pages/shared.styled.components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {
  StyledCol,
  BorderCol,
  ExtraContentContainer
} from "./styled.components";

interface JumbotronSplitSectionProps {
  title: string;
  buttonOptions?: {
    text: string;
    link: string;
  };
  extraContent?: React.ReactElement;
}

interface JumbotronSplitProps {
  title: string;
  description: React.ReactElement | string;
  bgColor: string;
  section1: JumbotronSplitSectionProps;
  section2: JumbotronSplitSectionProps;
}

const goTo = (link: string) => {
  window.open(link, "_blank");
};

const renderButton = (bgColor: string, opts: JumbotronSplitSectionProps) => {
  const { buttonOptions } = opts;

  if (buttonOptions !== undefined) {
    return (
      <LightButton
        className="align-self-center mt-auto"
        onClick={() => goTo(buttonOptions.link)}
        color={bgColor}
      >
        {buttonOptions.text}
      </LightButton>
    );
  }

  return null;
};

const JumbotronSplit: React.FC<JumbotronSplitProps> = ({
  title,
  description,
  section1,
  section2,
  bgColor
}) => {
  return (
    <StyledJumbotron backgroundColor={bgColor}>
      <Container className="text-center">
        <JumbotronHeader>{title}</JumbotronHeader>

        <BodyText>{description}</BodyText>

        <Row>
          <BorderCol lg={6} xs={12} className="d-flex flex-column">
            <JumbotronSubHeader className="mb-3">
              {section1.title}
            </JumbotronSubHeader>

            {section1.extraContent && (
              <Container className="mb-3 d-flex align-items-stretch">
                <ExtraContentContainer>
                  {section1.extraContent}
                </ExtraContentContainer>
              </Container>
            )}

            {renderButton(bgColor, section1)}
          </BorderCol>
          <StyledCol lg={6} xs={12} className="d-flex flex-column">
            <JumbotronSubHeader className="mb-3">
              {section2.title}
            </JumbotronSubHeader>

            {section1.extraContent && (
              <Container className="mb-3 d-flex align-items-stretch">
                <ExtraContentContainer>
                  {section2.extraContent}
                </ExtraContentContainer>
              </Container>
            )}

            {renderButton(bgColor, section2)}
          </StyledCol>
        </Row>
      </Container>
    </StyledJumbotron>
  );
};

export default JumbotronSplit;
