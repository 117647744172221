import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { CardText, ImageBanner, BannerTitle } from "./styled.components";

interface PageTitleBannerProps {
  title: string;
  titleTextColor?: string;
  content?: string;
  imageSrc: string;
}

const PageTitleBanner: React.FC<PageTitleBannerProps> = ({
  title,
  titleTextColor,
  content,
  imageSrc
}) => {
  return (
    <Card className="border-bottom-0">
      <ImageBanner src={imageSrc}>
        <Container className="ml-4">
          <BannerTitle color={titleTextColor}>{title}</BannerTitle>
        </Container>
      </ImageBanner>

      {content && (
        <Card.Body>
          <Container>
            <CardText>{content}</CardText>
          </Container>
        </Card.Body>
      )}
    </Card>
  );
};

export default PageTitleBanner;
