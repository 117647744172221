import React, { useState } from "react";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import PagesConfig, {
  Page as PageConfig,
  NavItem as NavItemConfig
} from "../../pages-config";
import {
  NavItem,
  NavLink,
  Logo,
  StyledBreadcrumb,
  BreadcrumbItem,
  DonateBreadcrumb,
  StyledNavDropdown,
  NavNestedDropdown
} from "./styled.components";
import { sizes as MediaSizes } from "../../providers/Theme";

const NESTED_DROPDOWN_WIDTH = 200;

const NavigationBar: React.FC = () => {
  const customRouter = useCustomRouter();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false);
  const [flip, setFlip] = useState(false);
  const [activeNestedDropdown, setActiveNestedDropdown] = useState<
    string | null
  >(null);

  const nestedDropDownRef = React.useRef<HTMLDivElement>(null);

  const evaluateDropdownFlip = () => {
    if (nestedDropDownRef.current) {
      const rect = nestedDropDownRef.current.getBoundingClientRect();
      const spaceToRight = window.innerWidth - rect.right;
      const shouldFlip = spaceToRight < NESTED_DROPDOWN_WIDTH;

      setFlip(shouldFlip);
    }
  };

  // Monitor window size to determine if dropdown should flip and mobile behavior
  React.useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${MediaSizes.lg}px)`);
    setIsMobile(mediaQuery.matches);

    requestAnimationFrame(evaluateDropdownFlip); // delay measurement to the next frame

    window.addEventListener("resize", evaluateDropdownFlip);
    return () => window.removeEventListener("resize", evaluateDropdownFlip);
  }, []);

  React.useEffect(() => {
    if (activeNestedDropdown) {
      evaluateDropdownFlip();
    } else {
      setFlip(false); // reset flip if no nested dropdown is active
    }
  }, [activeNestedDropdown]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const pageConfigs = Object.values(PagesConfig);
  const rootPage =
    pageConfigs.find(pg => pg.path === customRouter.rootPath) ||
    PagesConfig.home;

  const [activePage, setActivePage] = useState<PageConfig>(rootPage);

  const goToPage = (page: PageConfig) => {
    setActivePage(page);
    customRouter.push(page.path);
    setExpanded(false);
  };

  const navItems: NavItemConfig[] = Object.values(PagesConfig).filter(
    (config: PageConfig): config is NavItemConfig => !!config.showOnNavBar
  );

  return (
    <>
      <Navbar
        expanded={expanded}
        onSelect={() => setExpanded(false)}
        expand="lg"
      >
        <Nav className="flex-column">
          <Navbar.Brand>
            <Logo onClick={() => goToPage(PagesConfig.home)} />
          </Navbar.Brand>
        </Nav>

        <Navbar.Toggle
          onClick={toggleExpanded}
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse className="mt-auto pb-50" id="basic-navbar-nav">
          <Nav
            fill
            className="w-100"
            activeKey={activePage.key}
            onSelect={selectedKey =>
              goToPage(PagesConfig[selectedKey || "home"])
            }
          >
            {navItems.map((config: NavItemConfig) => (
              <NavItem key={config.key}>
                {config.navConfig.displayAs === "Link" ? (
                  <NavLink eventKey={config.key} color={config.navConfig.color}>
                    {config.title}
                  </NavLink>
                ) : (
                  <StyledNavDropdown
                    active={activePage.key === config.key}
                    renderMenuOnMount={true}
                    color={config.navConfig.color}
                    title={config.title}
                    id={`${config.key}-dropdown`}
                    onClick={(event: React.BaseSyntheticEvent) =>
                      event?.target?.id === `${config.key}-dropdown` &&
                      goToPage(PagesConfig[config.key])
                    }
                  >
                    {config.navConfig.children.map(child => {
                      if (typeof child === "string") {
                        return (
                          <NavDropdown.Item
                            className="w-100 px-0"
                            eventKey={child}
                            key={`${child}-dropdown-item`}
                          >
                            {PagesConfig[child].navDropDownComponent ??
                              PagesConfig[child].title}
                          </NavDropdown.Item>
                        );
                      } else {
                        // Render a nested dropdown for third-level menus

                        const childElementIsActive = child.children.some(
                          childKey => childKey === activePage.key
                        );

                        const nestedDrop = flip ? "left" : "right";

                        return (
                          <NavNestedDropdown
                            id={`${child.key}-nested-dropdown`}
                            key={`${child.key}-nested-dropdown`}
                            active={activePage.key === child.key}
                            title={PagesConfig[child.key].title}
                            color={config.navConfig.color}
                            show={
                              activeNestedDropdown === child.key ||
                              childElementIsActive
                            }
                            onMouseEnter={() => {
                              setActiveNestedDropdown(child.key);
                              requestAnimationFrame(evaluateDropdownFlip); // recalc for this dropdown
                            }}
                            onMouseLeave={() => {
                              setActiveNestedDropdown(null);
                            }}
                            onClick={(event: React.BaseSyntheticEvent) => {
                              if (
                                event?.target?.id ===
                                `${child.key}-nested-dropdown`
                              ) {
                                goToPage(PagesConfig[child.key]);
                              }
                            }}
                            ref={
                              activeNestedDropdown === child.key
                                ? nestedDropDownRef
                                : null
                            }
                            flip={flip}
                            drop={isMobile ? "down" : nestedDrop}
                            nestedDropdownWidth={NESTED_DROPDOWN_WIDTH}
                            className={`w-100 px-0 dropdown-item ${
                              activePage.key === child.key ? "active" : ""
                            }`}
                          >
                            {child.children.map(subChildKey => (
                              <NavDropdown.Item
                                className="w-100 px-0"
                                eventKey={subChildKey}
                                key={`${subChildKey}-dropdown-item`}
                                onClick={() => {
                                  goToPage(PagesConfig[subChildKey]); // Navigate to subpage
                                }}
                              >
                                {PagesConfig[subChildKey]
                                  .navDropDownComponent ??
                                  PagesConfig[subChildKey].title}
                              </NavDropdown.Item>
                            ))}
                          </NavNestedDropdown>
                        );
                      }
                    })}
                  </StyledNavDropdown>
                )}
              </NavItem>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <StyledBreadcrumb>
        {customRouter.breadCrumbs.map((breadCrumb, idx) => (
          <BreadcrumbItem
            key={`breadcrumb-${idx}`}
            active={idx === customRouter.breadCrumbs.length - 1} // Last element is not active
            onClick={() => goToPage(PagesConfig[breadCrumb.key])}
          >
            {breadCrumb.title}
          </BreadcrumbItem>
        ))}

        {activePage.key !== PagesConfig.donate.key && (
          <DonateBreadcrumb onClick={() => goToPage(PagesConfig.donate)}>
            Donate
          </DonateBreadcrumb>
        )}
      </StyledBreadcrumb>
    </>
  );
};

export default NavigationBar;
