import React from "react";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import {
  StyledCard,
  CardImg,
  StyledButton,
  CardTitle
} from "./styled.components";

interface TrainingCardProps {
  src: string;
  title: string;
  path: string;
}

const TrainingCard: React.FC<TrainingCardProps> = ({ src, title, path }) => {
  const customRouter = useCustomRouter();

  const goToPath = (path: string) => {
    customRouter.append(path);
  };

  return (
    <StyledCard>
      <CardImg variant="top" src={src} />

      <CardTitle onClick={() => goToPath(path)} className="mt-2 mb-0">
        {title}
      </CardTitle>

      <StyledButton onClick={() => goToPath(path)}>Learn More</StyledButton>
    </StyledCard>
  );
};

export default TrainingCard;
