import React from "react";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import { sitePurple } from "../../providers/Theme";
import OutReachImg from "../../assets/outreach.png";
import DevelopmentTeamImg from "../../assets/development-team.png";
import KrfInternshipDoc from "../../assets/krf-internship-program.pdf";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import {
  StyledRow,
  StyledCol,
  BannerTextWrapper,
  StyledLink,
  StyledContainer
} from "./styled.components";
import {
  TopPageContainer,
  Header,
  SubHeader,
  BodyText,
  PageContainer,
  StyledJumbotron,
  ListContainer,
  ListItem,
  JumbotronSubHeader,
  StyledBold
} from "../shared.styled.components";

const InternshipsAndVolunteers: React.FC = () => {
  const customRouter = useCustomRouter();

  const goToPath = (path: string) => {
    customRouter.push(path);
  };

  const goToExternalUrl = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <TopPageContainer className="ml-0">
        <Header color={sitePurple}>Internships and Volunteers</Header>
      </TopPageContainer>
      <StyledRow className="d-flex flex-wrap align-items-center">
        <StyledCol
          md={12}
          lg={4}
          className="d-flex justify-content-center align-items-center"
        >
          <Image fluid src={OutReachImg} />
        </StyledCol>
        <StyledCol md={12} lg={8}>
          <StyledContainer fluid className="px-5">
            <SubHeader>
              Outreach Team Engages Arizona Schools with information about
              Kyah's EPICourse for Arizona Schools
            </SubHeader>

            <BannerTextWrapper className="pt-1">
              <BodyText>
                The Kyah Rayne Foundation is actively encouraging Arizona's
                public, private, and charter schools to participate in the
                Arizona Stock Epinephrine Programs through a grassroots
                initiative, supported by the Arizona Department of Health,
                Arizona Department of Education, and the medical directors and
                superintendents of Maricopa and Pima Counties.
              </BodyText>
              <BodyText>
                Our <StyledBold>Outreach Team</StyledBold>, consisting of
                dedicated public health undergraduate student interns, has been
                tirelessly working to connect with over 3,000 schools across
                Arizona. We are thrilled to report that more than 3,000 school
                professionals have enrolled in{" "}
                <StyledLink
                  color="black"
                  onClick={() =>
                    goToPath("/trainings/kyahs-epicourse-for-arizona-schools")
                  }
                >
                  Kyah's EPICourse for Arizona Schools
                </StyledLink>
                . We encourage you to check with your local school to see if
                they have taken the course and enrolled in a stock epinephrine
                program. Join us in our mission to equip every school campus
                with epinephrine auto-injectors.
              </BodyText>

              <BodyText>
                Additionally, our outreach efforts extend to Arizona food
                service establishments, where we are working to enroll staff in
                Kyah's EPICourse for Food Service and ensure they obtain stock
                epinephrine to protect their patrons.
              </BodyText>
            </BannerTextWrapper>
          </StyledContainer>
        </StyledCol>
      </StyledRow>

      <StyledRow>
        <Col lg={6} xl={8} className="my-auto">
          <Container className="p-4">
            <SubHeader>
              Development Team creates course materials and builds partnerships
              across the United States
            </SubHeader>

            <BodyText>
              Our <StyledBold>Development Team</StyledBold> of public health
              graduate student interns is continuously updating course materials
              and strategizing new outreach methods. The team is also building
              partnerships with state agencies and food allergy organizations
              across the United States to implement our latest course, the
              National{" "}
              <StyledLink
                color="black"
                onClick={() =>
                  goToPath("/trainings/kyahs-epicourse-for-schools")
                }
              >
                Kyah's EPICourse for Schools
              </StyledLink>
              .
            </BodyText>

            <BodyText>
              We invite state entities and organizations to join us in this
              vital initiative. By collaborating with us, you can help enhance
              the knowledge and preparedness of schools across the nation in
              managing food allergies and ensuring the safety of students.
              Together, we can make a significant impact on the health and
              well-being of our communities.
            </BodyText>
          </Container>
        </Col>
        <Col
          lg={6}
          xl={4}
          className="d-flex justify-content-center align-items-center"
        >
          <Image fluid src={DevelopmentTeamImg} />
        </Col>
      </StyledRow>

      <PageContainer className="my-5">
        <SubHeader className="pb-2" color={sitePurple}>
          Public Health Internship Program
        </SubHeader>

        <BodyText>
          KRF has leveraged its public-private partnership with the University
          of Arizona Mel and Enid Zuckerman College of Public Health to develop
          an innovative internship program. KRF has been able to offer
          internship positions to undergraduate and graduate public health
          students since the Summer 2019 semester, providing opportunities to
          gain experience in outreach, health education, policy, and leadership.{" "}
          <StyledLink
            onClick={() =>
              goToExternalUrl(
                "https://www.publichealth.arizona.edu/undergraduate/internships"
              )
            }
          >
            Learn More
          </StyledLink>
        </BodyText>

        <JumbotronSubHeader color={sitePurple}>
          Ongoing/Past Projects
        </JumbotronSubHeader>
        <ListContainer>
          <ListItem>
            Food allergy and epinephrine training course and materials
            development: Kyah's EPICourse for Arizona Schools, National Kyah's
            EPICourse for Schools, and Kyah's EPICourse for Food Service
          </ListItem>
          <ListItem>
            Outreach to Arizona schools and food service establishments to
            facilitate enrollment in food allergy and epinephrine training
            courses
          </ListItem>
          <ListItem>
            Implementation of Arizona stock epinephrine programs in partnership
            with the Maricopa County Department of Public Health and Pima County
            Health Department
          </ListItem>
          <ListItem>Data collection and analysis on course enrollment</ListItem>
          <ListItem>Literature reviews on food allergy subjects</ListItem>
          <ListItem>
            Social media health education messaging and collaboration with other
            food allergy awareness organizations
          </ListItem>
          <ListItem>
            Building partnerships with state entities and organizations
            nationwide to implement the National Kyah's EPICourse for Schools
          </ListItem>
          <ListItem>
            Public health program planning and evaluation to improve health
            outcomes for individuals living with food allergies
          </ListItem>
          <ListItem>
            Grant application process: Researching funding opportunities,
            communicating with organizations that issue grants, writing grant
            proposals, and maintaining progress reports
          </ListItem>
          <ListItem>
            Policy and legislation research to improve accessibility to food
            allergy education and epinephrine devices
          </ListItem>
        </ListContainer>

        <JumbotronSubHeader color={sitePurple}>
          Internship Opportunities
        </JumbotronSubHeader>
        <BodyText>
          The University of Arizona Mel and Enid Zuckerman College of Public
          Health student interns typically dedicate 8-20 hours a week to KRF
          depending on their contact hour requirements and education level.
        </BodyText>
        <BodyText>
          More information about our internship program is available{" "}
          <StyledLink onClick={() => goToExternalUrl(KrfInternshipDoc)}>
            here.
          </StyledLink>
        </BodyText>
      </PageContainer>

      <StyledJumbotron className="mb-0" backgroundColor={sitePurple}>
        <Container>
          <h2 className="font-weight-bold">Join Us</h2>

          <BodyText>
            Our mission at KRF is to ensure that communities have the tools they
            need to manage food allergies, including awareness and education
            surrounding food allergies and increased access to Epinephrine
            Auto-Injectors. To reach our goal, we need your passion and
            participation. Deaths from food allergies are preventable, and
            together we can make the world a safer place for people with food
            allergies.
          </BodyText>
        </Container>
      </StyledJumbotron>
    </>
  );
};

export default InternshipsAndVolunteers;
