import styled from "styled-components";
import CardDeck from "react-bootstrap/CardDeck";

export const StyledCardDeck = styled(CardDeck)`
  justify-content: center;

  .card {
    ${({ theme }) => theme.sm`
      min-width: 100%;
      max-width: 100%
    `};

    ${({ theme }) => theme.lg`
      min-width: 350px;
      max-width: 350px;
    `};
  }
`;
