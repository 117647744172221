import React from "react";
import {
  PageContainer,
  Header,
  RoundedButton,
  StyledLink,
  BodyText
} from "../shared.styled.components";
import EpinephrineFAQItem from "../../components/EpinephrineFAQItem";
import { siteDarkBlue } from "../../providers/Theme";
import Container from "react-bootstrap/Container";
import { goToExternalUrl } from "../../utils";

const EpinephrineFAQ: React.FC = () => {
  return (
    <PageContainer>
      <Header>Epinephrine FAQ: What You Need to Know</Header>

      <EpinephrineFAQItem
        title="What is Epinephrine?"
        bodyText="Epinephrine (also known as adrenaline) is the first and most important treatment for anaphylaxis, a severe, life-threatening allergic reaction. It is both a hormone and a medication that works quickly to:"
        faqList={{
          listItems: [
            { text: "Relax the muscles in the airways to improve breathing" },
            {
              text: "Constrict blood vessels to prevent swelling and increase blood pressure"
            },
            { text: "Stimulate the heart and improve circulation" },
            {
              text: "Reduce hives and swelling, particularly around the face and lips"
            }
          ]
        }}
      />

      <EpinephrineFAQItem
        title="How Does an Epinephrine Auto-Injector (EAI) Work?"
        bodyText="An Epinephrine Auto-Injector (EAI) is a device that delivers a single dose of epinephrine to treat severe allergic reactions (anaphylaxis)."
        faqList={{
          listHeader: "How it works:",
          listItems: [
            {
              text: "Narrowing blood vessels increases blood pressure, improving circulation"
            },
            {
              text: "Relaxing airway muscles reduces wheezing and opens the airways to improve breathing"
            },
            {
              text: "Reduces swelling in areas like the face, lips, and throat"
            }
          ]
        }}
      />

      <EpinephrineFAQItem
        title="How to Use an EAI (Epinephrine Auto-Injector)"
        faqList={{
          listType: "ordered",
          listItems: [
            {
              text: "Hold the injector firmly in your fist with the needle tip (usually orange or red) facing away from your body"
            },
            {
              text: "Place the tip firmly against the outer thigh (through clothing if necessary)"
            },
            {
              text: "Press down firmly to inject and hold for 10 seconds to ensure the full dose is delivered"
            },
            {
              text: "Call 911 immediately after use. The effects of epinephrine can wear off, and a second reaction can occur"
            }
          ]
        }}
      />

      <EpinephrineFAQItem
        title="What is Neffy?"
        bodyText="Neffy is a nasal spray version of epinephrine, offering a needle-free option for individuals with severe allergies. It works in the same way as an EAI but is delivered through the nose."
        faqList={{
          listItems: [
            { text: "Fast-acting: Absorbed quickly through the nasal mucosa" },
            { text: "No needles required" },
            { text: "For individuals aged 12 and older" },
            {
              text: "Call 911 immediately after using Neffy, as the effects of epinephrine can wear off, and a second reaction (biphasic anaphylaxis) can happen"
            }
          ]
        }}
      />

      <EpinephrineFAQItem
        title="How to Use Neffy (Nasal Spray)"
        faqList={{
          listItems: [
            { text: "Insert the nozzle into one nostril" },
            { text: "Press firmly to release the epinephrine spray" },
            {
              text: "Inhale to ensure it's absorbed through the nasal mucosa"
            },
            { text: "Hold for a few seconds and then immediately call 911" }
          ]
        }}
      />

      <EpinephrineFAQItem
        title="Should I Call 911 After Administering Epinephrine (EAI or Neffy)?"
        bodyText="Yes! Always call 911 after administering epinephrine. Even if the person starts to feel better, the effects of epinephrine can wear off, and a second allergic reaction (biphasic anaphylaxis) can occur. Medical attention is essential."
      />

      <EpinephrineFAQItem
        title="Can I Use an EAI or Neffy on a Different Body Part?"
        faqList={{
          listItems: [
            { text: "EAI: Should only be injected into the outer thigh" },
            { text: "Neffy: Should only be sprayed into the nostril" }
          ]
        }}
      />

      <EpinephrineFAQItem
        title="How Many EAI Devices or Neffy Should I Carry?"
        bodyText="It's recommended to carry two epinephrine devices (either two auto-injectors or one EAI and one Neffy) at all times in case a second dose is needed."
      />

      <EpinephrineFAQItem
        title="What Happens if I Get a Dose of Epinephrine When Not Needed?"
        bodyText="If epinephrine is injected into hands, feet, or other areas besides the thigh, it can cause blood flow issues. If this happens, go to the nearest emergency room immediately for treatment."
      />

      <EpinephrineFAQItem
        title="How to Dispose of Used EAI or Neffy"
        faqList={{
          listItems: [
            {
              text: "Used auto-injectors or Neffy devices should be taken to a healthcare provider or emergency room for safe disposal"
            },
            { text: "Do not throw away used devices in the trash" }
          ]
        }}
      />

      <EpinephrineFAQItem
        title="Is a Dose of Epinephrine Dangerous?"
        faqList={{
          listHeader:
            "A properly administered dose of epinephrine is generally safe and life-saving. Common side effects include:",
          listItems: [
            { text: "Increased heart rate" },
            { text: "Nausea, vomiting, and dizziness" },
            { text: "Anxiety" },
            {
              text: "While rare, more serious effects (like heart arrhythmias) can occur, especially when epinephrine is administered incorrectly (e.g., intravenously)"
            }
          ]
        }}
      />

      <EpinephrineFAQItem
        title="What Epinephrine Auto-Injectors and Nasal Sprays are Available?"
        faqList={{
          listItems: [
            {
              text: "Epinephrine Auto-Injectors (EAI):",
              children: ["EpiPen", "Auvi-Q", "AdrenaClick"]
            },
            { text: "Nasal Spray:", children: ["Neffy"] }
          ]
        }}
      />

      <EpinephrineFAQItem
        title="How Much Does Epinephrine Cost?"
        faqList={{
          listItems: [
            { text: "A 2-pack of auto-injectors typically costs $650-$700" },
            {
              text: "Neffy nasal spray pricing ranges from $300-$350 but can vary depending on insurance and location"
            },
            { text: "Insurance coverage may help lower out-of-pocket costs" }
          ]
        }}
      />

      <Container fluid className="p-4">
        <RoundedButton
          color={siteDarkBlue}
          onClick={() =>
            goToExternalUrl(
              "https://docs.google.com/spreadsheets/d/1t3-cmiKzSRqIV3_SXy3fcqlqqgbKi47CwriSg3o_NM0/edit?gid=0#gid=0"
            )
          }
        >
          Epinephrine Discount Resources
        </RoundedButton>
      </Container>

      <EpinephrineFAQItem
        title="Why is Epinephrine So Expensive?"
        bodyText="The cost of epinephrine auto-injectors has increased significantly over the years. Concerns about pricing and affordability have led to calls for more price transparency and generic alternatives, including Neffy. In 2017, Mylan Pharmaceuticals settled a $465 million lawsuit for overcharging the U.S. government for epinephrine auto-injectors."
        footerElement={
          <BodyText>
            <StyledLink
              href="https://www.cnbc.com/2017/08/17/mylan-finalizes-465-million-epipen-settlement-with-justice-department.html"
              target="blank"
            >
              Read more.
            </StyledLink>
          </BodyText>
        }
      />
    </PageContainer>
  );
};

export default EpinephrineFAQ;
