import styled from "styled-components";
import Image from "react-bootstrap/Image";

export const ItalicHeader = styled.h5`
  font-style: italic;
`;

export const StyledImage = styled(Image)`
  max-height: 500px;
`;
