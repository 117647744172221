import React from "react";
import {
  BlueLine,
  JumbotronSubHeader,
  SubHeader
} from "../../pages/shared.styled.components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ItalicHeader, StyledImage } from "./styled.components";

interface Toolkit {
  title: string;
  subTitle?: string;
  body: React.ReactElement;
  file: string;
  thumbnail: string;
}

interface TrainingToolkitsForSchoolsProps {
  header: string;
  toolkits: Toolkit[];
}

const openDocumentInNewTab = (file: string) => {
  window.open(file, "_blank");
};

const TrainingToolkitsForSchools: React.FC<TrainingToolkitsForSchoolsProps> = ({
  header,
  toolkits
}) => {
  return (
    <>
      <SubHeader className="mt-5">{header}</SubHeader>
      <BlueLine />

      {toolkits.map(toolkit => (
        <Row className="mb-5">
          <Col
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <StyledImage
              fluid
              src={toolkit.thumbnail}
              onClick={() => openDocumentInNewTab(toolkit.file)}
            />
          </Col>
          <Col>
            <JumbotronSubHeader>{toolkit.title}</JumbotronSubHeader>

            {toolkit.subTitle && (
              <ItalicHeader>{toolkit.subTitle}</ItalicHeader>
            )}

            {toolkit.body}
          </Col>
        </Row>
      ))}
    </>
  );
};

export default TrainingToolkitsForSchools;
