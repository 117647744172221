import React from "react";
import VideoLibrary from "../VideoLibrary";

const EpiCourseForSchoolsVideoLibrary: React.FC = () => {
  return (
    <VideoLibrary
      videos={[
        {
          src: "https://player.vimeo.com/video/743773430?h=fb8ff489a5?maxwidth=100",
          title: "Recognizing Signs and Symptoms"
        },
        {
          src: "https://player.vimeo.com/video/743773239?h=adc5abdb5c",
          title: "How to Administer an EAI"
        },
        {
          src: "https://player.vimeo.com/video/743773068?h=c8548b949b",
          title: "Creating Safe Environments"
        },
        {
          src: "https://www.youtube-nocookie.com/embed/RZ6z1WfrFas?controls=0",
          title: "Steps To Administer An EAI"
        },
        {
          src: "https://www.youtube-nocookie.com/embed/wzmDb2gseWk?controls=0",
          title: "EAI Infographic"
        },
        {
          src: "https://www.youtube-nocookie.com/embed/nQG78NELzx0?controls=0",
          title: "Steps To Administer An EAI (Spanish)"
        },
        {
          src: "https://www.youtube-nocookie.com/embed/LV7RRxZolDQ?controls=0",
          title: "EAI Infographic (Spanish)"
        }
      ]}
    />
  );
};

export default EpiCourseForSchoolsVideoLibrary;
