import React from "react";
import PeopleWithFoodAllergies from "../../assets/people-with-food-allergies.png";
import EveryTenSeconds from "../../assets/every-ten-seconds.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {
  PageContainer,
  Header,
  SubHeader,
  ListContainer,
  ListItem,
  BodyText,
  OrderedListContainer
} from "../shared.styled.components";
import { siteDarkBlue } from "../../providers/Theme";
import { ReferenceLink } from "./styled.components";

const FactsAndFigures: React.FC = () => {
  return (
    <PageContainer>
      <Header>Facts and Figures</Header>

      <Row className="mt-4">
        <Col md={12} lg={7}>
          <ListContainer>
            <ListItem>
              An estimated 33 million Americans live with food allergies.
              <sup>1</sup>
            </ListItem>
            <ListItem>
              1 in 13 children suffer from food allergies, and that number is on
              the rise.<sup>2</sup>
            </ListItem>
            <ListItem>
              Each year in the U.S., 200,000 people require emergency medical
              care for allergic reactions to food.<sup>3</sup>
            </ListItem>
            <ListItem>
              The prevalence of food allergies among children increased 18%
              during 1997-2007, and allergic reactions to foods have become the
              most common cause of anaphylaxis in community health settings.
              <sup>2</sup>
            </ListItem>
          </ListContainer>
        </Col>
        <Col md={12} lg={5} className="d-flex flex-wrap align-items-center">
          <Image fluid src={PeopleWithFoodAllergies} />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={12} lg={7}>
          <SubHeader color={siteDarkBlue}>
            Is There a Cure For Food Allergies?
          </SubHeader>

          <BodyText>
            There is no cure for food allergies. You can manage food allergies
            with prevention and treatment. It is among the country's most
            common, but over looked diseases.<sup>3</sup>
          </BodyText>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col
          md={12}
          lg={4}
          className="d-flex flex-wrap align-items-center mb-4"
        >
          <Image fluid src={EveryTenSeconds} />
        </Col>

        <Col
          md={12}
          lg={8}
          className="d-flex flex-column justify-content-center text-center"
        >
          <SubHeader color={siteDarkBlue}>Severe Allergic Reactions</SubHeader>

          <BodyText>
            Epinephrine must be administered within minutes of anaphylaxis to be
            successful. Epinephrine is the only available treatment for severe
            allergic reactions.<sup>4,5</sup>
          </BodyText>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <SubHeader color={siteDarkBlue}>
            Are Food Allergies a Concern in Schools?
          </SubHeader>

          <ListContainer>
            <ListItem>
              Studies show that 16-18% of children with food allergies have had
              a reaction from accidentally eating food allergens while at
              school.<sup>2</sup>
            </ListItem>

            <ListItem>
              25% of anaphylaxis reactions at school occur in students
              previously undiagnosed with a severe allergy to food, insect
              venom, latex, or medication.<sup>2</sup>
            </ListItem>
          </ListContainer>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <SubHeader color={siteDarkBlue}>References</SubHeader>

          <OrderedListContainer>
            <ListItem>
              Gupta RS, Warren CM, Smith BM, et al. Prevalence and severity of
              food allergies among US adults. JAMA Netw Open. 2019;2(1).
              doi:10.1001/jamanetworkopen.2018.5630.
            </ListItem>
            <ListItem>
              Centers for Disease Control and Prevention (2022). Food allergies.
              Accessed October 29, 2024. Available from:{" "}
              <ReferenceLink href="https://www.cdc.gov/healthyschools/foodallergies/index.html">
                https://www.cdc.gov/healthyschools/foodallergies/index.html
              </ReferenceLink>
              .
            </ListItem>
            <ListItem>
              Asthma and Allergy Foundation of America (2018). Allergy facts.
              Accessed October 29, 2024. Available from:{" "}
              <ReferenceLink href="https://www.aafa.org/allergy-facts/">
                https://www.aafa.org/allergy-facts/
              </ReferenceLink>
            </ListItem>
            <ListItem>
              Clark S, Bock SA, Gaeta TJ, et al. Multicenter study of emergency
              department visits for food allergies. J Allergy Clin Immunol.
              2004;113(2):347-352. doi:10.1016/j.jaci.2003.10.053.
            </ListItem>
            <ListItem>
              Kothari P. Epinephrine is the only effective treatment for
              anaphylaxis. Harvard Health Blog. Published July 9, 2020. Accessed
              October 29, 2024. Available from:{" "}
              <ReferenceLink href="https://www.health.harvard.edu/blog/epinephrine-is-the-only-effective-treatment-for-anaphylaxis-2020070920523">
                https://www.health.harvard.edu/blog/epinephrine-is-the-only-effective-treatment-for-anaphylaxis-2020070920523
              </ReferenceLink>
            </ListItem>
          </OrderedListContainer>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default FactsAndFigures;
