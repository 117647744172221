import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import RisksGraphic from "../../assets/risks-graphic.png";
import FoodAllergyReactionImg from "../../assets/food-allergy-reaction.jpg";
import FareEmergencyActionPlan from "../../assets/fare-emergency-care-plan.pdf";
import {
  Header,
  BodyText,
  SubHeader,
  ListContainer,
  ListItem,
  StyledLink,
  StyledJumbotron,
  TopPageContainer,
  StyledBold
} from "../shared.styled.components";
import { ImageContainer } from "./styled.components";
import { siteDarkBlue } from "../../providers/Theme";

const FoodAllergyRisks: React.FC = () => {
  return (
    <>
      <TopPageContainer>
        <Header>Food Allergy Risks</Header>

        <BodyText>
          Every food allergy has the potential to lead to anaphylaxis. Reactions
          can vary each time, and even if someone has experienced only a mild
          reaction in the past, the next one could be more severe.
        </BodyText>

        <Row>
          <Col md={12} lg={6} className="d-flex flex-wrap align-items-center">
            <ImageContainer>
              <Image fluid src={RisksGraphic} />
            </ImageContainer>
          </Col>
          <Col md={12} lg={6}>
            <StyledBold>
              <BodyText color={siteDarkBlue}>
                Factors that can increase a persons's risk of developing a food
                allergy can include:
              </BodyText>
            </StyledBold>
            <ListContainer>
              <ListItem>
                <b>Genetic History:</b> A family history of asthma, eczema,
                hives, or other allergies can increase the risk of developing
                food allergies.
              </ListItem>
              <ListItem>
                <b>Geography:</b> The risk for food allergies is higher in urban
                than rural areas.
              </ListItem>
              <ListItem>
                <b>Age:</b> Food allergies are more common in children, although
                some may outgrow them depending on the type and severity of the
                allergy.
              </ListItem>
              <ListItem>
                <b>Ethnicity:</b> African American children are at a higher risk
                of developing food allergies.
              </ListItem>
              <ListItem>
                <b>Asthma:</b> People with asthma are more likely to also have
                food allergies, and vice versa.
              </ListItem>
              <ListItem>
                <b>Other Allergic Conditions:</b>Individuals with one food
                allergy are at an increased risk of developing additional food
                allergies.
              </ListItem>
            </ListContainer>
          </Col>
        </Row>

        <SubHeader className="mt-5" color={siteDarkBlue}>
          Risks At Schools
        </SubHeader>

        <Row>
          <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
            <BodyText>
              Studies show that 1 out of 6 children with food allergies have
              reacted to accidentally eating food allergens while at school
              (Nowak-Wegrzyn et. al., 2001).
            </BodyText>
            <BodyText>
              Allergic reactions can occur in classrooms, buses, field trips,
              activities, P.E., and during recess. A majority of school-related
              anaphylactic events occur outside the cafeteria (Nowak-Wegrzyn et.
              al., 2001).
            </BodyText>
            <BodyText>
              Staff should always have quick access to life-saving epinephrine
              in case there is a food allergy emergency.
            </BodyText>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            lg={{ span: 6, order: 2 }}
            className="d-flex flex-wrap align-items-center"
          >
            <ImageContainer>
              <Image fluid src={FoodAllergyReactionImg} />
            </ImageContainer>
          </Col>
        </Row>

        <BodyText>
          Students who are at risk for an allergic reaction should keep
          life-saving epinephrine, such as an Epinephrine Auto- Injector (EAI)
          with them at all times even during school activities. It is
          recommended that parents/legal guardians and students keep an
          emergency plan, like{" "}
          <StyledLink href={FareEmergencyActionPlan} target="_blank">
            FARE's Food Allergy Emergency Care Plan
          </StyledLink>{" "}
          which includes:
        </BodyText>

        <ListContainer>
          <ListItem>
            Personal information (name, date of birth, allergies, weight)
          </ListItem>
          <ListItem>Outline of recommended treatment</ListItem>
          <ListItem>Emergency contacts</ListItem>
        </ListContainer>

        <BodyText>
          School staff should work collaboratively with parents and their
          doctors to ensure an appropriate care plan is established to address
          the child's allergies.
        </BodyText>

        <SubHeader>
          Best practices for reducing food allergen exposure in schools include:
        </SubHeader>

        <ListContainer>
          <ListItem>
            <b>Classrooms:</b> Establish clear "food rules" for the classroom
            and communicate them with parents and guardians. These rules could
            include ensuring all ingredients are labeled on foods shared with
            the class to help prevent allergic reactions.
          </ListItem>
          <ListItem>
            <b>Cafeterias:</b> Designate allergen-safe seating areas and educate
            children about the risks of food sharing. This includes talking to
            kids about how sharing food can unintentionally cause harm to
            someone with allergies.
          </ListItem>
          <ListItem>
            <b>Transportation:</b> Encourage children not to eat or share food
            on buses to avoid exposure to allergens in confined spaces.
          </ListItem>
          <ListItem>
            <b>Field trips:</b> Invite parents and guardians of children with
            food allergies to serve as additional chaperones to help manage
            allergy risks and ensure safety.
          </ListItem>
          <ListItem>
            <b>Recess:</b> Recommend that children wash their hands after recess
            before handling or eating food to reduce the risk of allergen
            transfer.
          </ListItem>
        </ListContainer>
      </TopPageContainer>

      <StyledJumbotron fluid className="m-0">
        <Container className="text-center">
          <h1 className="d-inline">
            20% - 25% of school-related anaphylactic events occur in children
            without a known history of food allergy{" "}
          </h1>
          <h3 className="d-inline">(CDC, 2013).</h3>
        </Container>
      </StyledJumbotron>
    </>
  );
};

export default FoodAllergyRisks;
