import React from "react";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import { sitePurple } from "../../providers/Theme";
import StepInStepUp from "../../assets/step-in-step-up.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {
  StyledCol,
  BorderCol,
  ColumnContainer,
  StyledRoundedButton
} from "./styled.components";
import {
  TopPageContainer,
  Header,
  SubHeader,
  BodyText,
  ListContainer,
  ListItem,
  StyledJumbotron,
  JumbotronHeader,
  JumbotronSubHeader,
  StyledBold
} from "../shared.styled.components";
import { goToExternalUrl } from "../../utils";

const AdvocateForChange: React.FC = () => {
  const customRouter = useCustomRouter();

  const goToPath = (path: string) => {
    customRouter.push(path);
  };

  return (
    <>
      <TopPageContainer>
        <Header color={sitePurple}>Advocate for Change</Header>

        <BodyText>
          The Kyah Rayne Foundation is committed to supporting families affected
          by food allergies and advancing community education on this urgent
          health issue. Our mission is to enhance the quality of life for those
          with food allergies and prevent anaphylaxis-related fatalities through
          increased awareness, targeted educational programs, fundraising
          efforts, and impactful policy advocacy.
        </BodyText>

        <BodyText>
          We are actively advocating for a new House Bill to establish an
          Emergency Use Auto-Injectable Epinephrine Program in food service
          facilities at higher education institutions across Arizona. Modeled
          after the successful 2018 Maryland House Bill 1473, this legislation
          would make Arizona the second state to implement this lifesaving
          protocol, advancing safety and preparedness within our communities.
          Together, by stepping in and stepping up, we can save lives.
        </BodyText>

        <BodyText>
          To date, KRF has trained over 3,000 Arizona school personnel to
          recognize allergic reactions and safely administer stock epinephrine.
          Recently, KRF developed the National Kyah's EPICourse for Schools,
          aiming to implement a nationwide program to protect students with
          severe allergies.
        </BodyText>

        <Row>
          <Col xl={8}>
            <StyledCol>
              <SubHeader className="mx-auto text-center pb-3">
                Be an Advocate for Food Allergy Awareness
              </SubHeader>

              <BodyText>
                You can make a difference in the lives of people with food
                allergies. By advocating for greater awareness, safer
                environments, and policy change, you can help protect those at
                risk.
              </BodyText>

              <BodyText className="mb-0">
                Here's how you can get involved:
              </BodyText>

              <ListContainer className="pb-2">
                <ListItem>
                  <StyledBold>Raise Awareness:</StyledBold> Educate parents,
                  families, schools, and policymakers about the dangers of food
                  allergies and the importance of emergency treatment.
                </ListItem>
                <ListItem>
                  <StyledBold>Support Schools:</StyledBold> Advocate for schools
                  to stock epinephrine and train staff to recognize and respond
                  to allergic reactions.
                </ListItem>
                <ListItem>
                  <StyledBold>Be Prepared:</StyledBold> Learn how to spot the
                  signs of anaphylaxis and use epinephrine devices in an
                  emergency.
                </ListItem>
                <ListItem>
                  <StyledBold>Promote Legislation:</StyledBold> Push for laws
                  that ensure emergency allergy treatment programs are in place
                  in schools and public spaces.
                </ListItem>
                <ListItem>
                  <StyledBold>Save Lives:</StyledBold> Help prevent deaths by
                  supporting policies and practices that make food allergies
                  manageable.
                </ListItem>
              </ListContainer>

              <BodyText>
                Your advocacy can help create a safer world for people with food
                allergies. Together, we can reduce the risks and save lives.
              </BodyText>
            </StyledCol>
          </Col>
          <Col
            xl={4}
            className="d-flex justify-content-center align-items-center"
          >
            <Image fluid src={StepInStepUp} />
          </Col>
        </Row>
      </TopPageContainer>

      <StyledJumbotron backgroundColor={sitePurple} className="mb-0">
        <Row className="w-100">
          <JumbotronHeader>Take Action</JumbotronHeader>
        </Row>
        <Row className="w-100">
          <BorderCol lg={6} xs={12}>
            <ColumnContainer>
              <JumbotronSubHeader>
                Register for Kyah's EPICourse
              </JumbotronSubHeader>
              <BodyText>
                Take our free, online training courses to learn about food
                allergies, anaphylaxis, and how to administer epinephrine.
              </BodyText>

              <StyledRoundedButton onClick={() => goToPath("/trainings")}>
                Register Now
              </StyledRoundedButton>
            </ColumnContainer>
          </BorderCol>
          <Col lg={6} xs={12}>
            <ColumnContainer>
              <JumbotronSubHeader>
                Make Sure Your Voice is Heard
              </JumbotronSubHeader>
              <BodyText>
                Tell Congress that epinephrine saves lives, and that it must be
                affordable and accessible to everyone who needs it.
              </BodyText>

              <StyledRoundedButton
                onClick={() =>
                  goToExternalUrl(
                    "https://www.house.gov/representatives/find-your-representative"
                  )
                }
              >
                Find Your Representative
              </StyledRoundedButton>
            </ColumnContainer>
          </Col>
        </Row>
      </StyledJumbotron>
    </>
  );
};

export default AdvocateForChange;
