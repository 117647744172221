import styled from "styled-components";
import Col from "react-bootstrap/Col";

export const StyledCol = styled(Col)``;

export const BorderCol = styled(StyledCol)`
  border-bottom: none;

  ${({ theme }) => theme.xs`
    border-right: none;
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 1.5rem;
  `}

  ${({ theme }) => theme.lg`
    border-right: 4px solid white;
    margin-bottom: 0;
  `}
`;

export const ExtraContentContainer = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  display: flex;
`;
