import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { siteGray } from "../../providers/Theme";
import { BODY_FONT_SIZE, LightButton } from "../shared.styled.components";

export const StyledContainer = styled(Container)`
  background-color: ${siteGray};
`;

export const CardsRow = styled(Row)`
  background-color: ${siteGray};
`;

export const StyledText = styled.p`
  font-size: ${BODY_FONT_SIZE};
  margin-bottom: 0;
`;

interface StyledLinkProps {
  color: string;
}

export const StyledLink = styled.a<StyledLinkProps>`
  font-size: ${BODY_FONT_SIZE};
  font-weight: 800;
  color: ${props => props.color};
  text-decoration: underline;

  &:hover {
    color: ${props => props.color};
  }
`;

export const SubText = styled.p`
  font-size: 16px;
  margin-bottom: 0;
`;

export const StyledLightButton = styled(LightButton)`
  ${({ theme }) => theme.xs`
    margin-top: 1.5rem
  `}

  ${({ theme }) => theme.xl`
    width: 100%;
    margin-top: 0;
  `}
`;

export const GrayContainer = styled(Container).attrs({
  className: "d-flex flex-column my-5"
})`
  background-color: ${siteGray};

  padding: 1.5rem;
`;
