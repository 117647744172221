import styled from "styled-components";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";

export const CardText = styled(Card.Text)`
  font-size: 22px;
  text-align: center;
`;

interface ImageBannerProps {
  src: string;
}

export const ImageBanner = styled(Row)<ImageBannerProps>`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center, center;

  padding: 3.5rem 1rem;
`;

export const BannerTitle = styled.h1`
  color: ${props => props.color ?? "white"};
  font-weight: 900;
  font-size: 3rem;
`;
