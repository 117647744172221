import React from "react";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import PossibleSideEffectsImg from "../../assets/possible-side-effects.png";
import HowToGetImg from "../../assets/how-to-get.png";
import StorageImg from "../../assets/storage.png";
import DisposalImg from "../../assets/disposal.png";
import EpiFirstEpiFastImg from "../../assets/epi-first-epi-fast.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Image from "react-bootstrap/Image";
import {
  PageContainer,
  Header,
  BodyText,
  SubHeader,
  ListContainer,
  ListItem,
  StyledJumbotron,
  TopPageContainer,
  RoundedButton
} from "../shared.styled.components";
import {
  BlueBoldText,
  NoMarginBodyText,
  StyledTabs,
  TabsContainer,
  TabBody,
  VideoContainer
} from "./styled.components";
import { siteDarkBlue, sitePurple } from "../../providers/Theme";

const Epinephrine: React.FC = () => {
  const customRouter = useCustomRouter();

  return (
    <>
      <TopPageContainer>
        <Header>Epinephrine</Header>

        <SubHeader>What is Epinephrine?</SubHeader>

        <BodyText>
          <b>Epinephrine</b>, also known as adrenaline, is both a hormone and a
          medication. It is the <b>FIRST</b> and <b>MOST</b> important treatment
          for anaphylaxis. Epinephrine works by relaxing the muscles in the
          airways and constricting blood vessels to prevent swelling, helping to
          reverse the life-threatening symptoms of anaphylaxis.
        </BodyText>

        <BodyText>
          <b>
            Epinephrine is the most effective treatment for anaphylaxis and can
            prevent death if administered quickly.
          </b>{" "}
          Antihistamines, such as Benadryl, are commonly used but are not
          effective for treating anaphylaxis because they act too slowly.
        </BodyText>

        <BodyText>
          Epinephrine is available in several forms, including auto-injectors,
          syringes, and a new nasal spray, all of which require a prescription
          from your doctor. Each device may have its own set of instructions for
          use, so it's important to familiarize yourself with your specific
          device and understand how to use it properly.
        </BodyText>

        <SubHeader>What is an Epinephrine Auto-Injector?</SubHeader>

        <BodyText>
          <b>Epinephrine auto-injectors (EAIs)</b> are devices used to deliver a
          dose of epinephrine for the treatment of severe allergic reactions,
          known as anaphylaxis. EAIs are produced by several pharmaceutical
          companies in the U.S. and are typically shaped like a pen, with the
          exception of Auvi-Q, which has a unique design.
        </BodyText>

        <BodyText>
          Understanding what EAIs are and how they work is essential in
          responding quickly and effectively if someone is experiencing a severe
          allergic reaction or anaphylaxis.
        </BodyText>
      </TopPageContainer>

      <Container fluid="md">
        <Row className="justify-content-md-center my-5">
          <Col>
            <SubHeader className="mb-4" color={siteDarkBlue}>
              Watch the video below to learn more about an EAI.
            </SubHeader>
            <VideoContainer>
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube-nocookie.com/embed/wzmDb2gseWk?controls=0"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
              />
            </VideoContainer>
          </Col>
        </Row>
      </Container>

      <StyledJumbotron>
        <Container className="text-center">
          <h1>
            <b>The #1 reason why anaphylaxis can cause death:</b> epinephrine
            was NOT given fast enough.
          </h1>
        </Container>
      </StyledJumbotron>

      <PageContainer>
        <SubHeader color={sitePurple}>IMPORTANT</SubHeader>

        <BodyText>
          If you think someone is having a severe allergic reaction, do not wait
          to see if symptoms get worse. When in doubt, always administer
          epinephrine.
        </BodyText>

        <div className="text-center">
          <NoMarginBodyText>
            <b>Remember this phrase:</b>
          </NoMarginBodyText>
          <BlueBoldText>
            EPI FIRST, EPI FAST, and when in doubt, ALWAYS give Epi.
          </BlueBoldText>
        </div>
      </PageContainer>

      <StyledJumbotron>
        <Container className="text-center">
          <Image fluid src={EpiFirstEpiFastImg} />
        </Container>
      </StyledJumbotron>

      <PageContainer>
        <Row>
          <Col lg={7}>
            <SubHeader color={siteDarkBlue}>Facts About Epinephrine</SubHeader>

            <TabsContainer className="p-0 mt-4">
              <StyledTabs defaultActiveKey="possible-side-effects">
                <Tab
                  className="pb-5"
                  eventKey="possible-side-effects"
                  title="Possible Side Effects"
                >
                  <TabBody>
                    <BodyText>
                      Possible life threatening side effects include: skin
                      redness, swelling, warmth, or tenderness at the site of
                      injection, increased heart rate, nausea, vomiting,
                      seating, dizziness.
                    </BodyText>

                    <BodyText>
                      <b>REMEMBER:</b> The benefits of receiving epinephrine are
                      far greater than the risk of side effects.
                    </BodyText>

                    <Container>
                      <Image fluid src={PossibleSideEffectsImg} />
                    </Container>
                  </TabBody>
                </Tab>
                <Tab className="pb-5" eventKey="how-to-get" title="How To Get">
                  <TabBody>
                    <BodyText>
                      <b>For Patients/Students:</b> It requires a prescription
                      from a physician and comes as 2 single-dose disposable
                      devices. It is important for people with food allergies to
                      always carry 2 devices. Remind students to always carry an
                      extra device with them at all times.
                    </BodyText>

                    <BodyText>
                      <b>For Schools:</b> EAIs are a stock medication so the
                      prescription is not written for a specific patient but for
                      the school's emergency use. Once you complete this
                      training course, your school can then apply for a stock
                      epinephrine prescription.
                    </BodyText>

                    <Container>
                      <Image fluid src={HowToGetImg} />
                    </Container>
                  </TabBody>
                </Tab>
                <Tab className="pb-5" eventKey="storage" title="Storage">
                  <TabBody>
                    <ListContainer>
                      <ListItem>DO NOT refrigerate.</ListItem>
                      <ListItem>
                        Store EAIs at room temperature away from light, excess
                        heat, and moisture (i.e. not in the bathroom or in the
                        bus glove box).
                      </ListItem>
                      <ListItem>
                        Keep EAIs in their plastic carrying tubes, tightly
                        closed, until needed.
                      </ListItem>
                    </ListContainer>

                    <Container>
                      <Image fluid src={StorageImg} />
                    </Container>
                  </TabBody>
                </Tab>

                <Tab className="pb-5" eventKey="disposal" title="Disposal">
                  <TabBody>
                    <BodyText>
                      Always check to make sure your EAI has not expired, become
                      discolored, or contain particles. If you must dispose of
                      an EAI, please try to obtain a replacement before doing
                      so.
                    </BodyText>

                    <u>How to dispose:</u>

                    <ListContainer>
                      <ListItem>Do NOT flush down the toilet.</ListItem>
                      <ListItem>Discard in a sharps container.</ListItem>
                      <ListItem>Look for medicine take-back programs.</ListItem>
                    </ListContainer>

                    <Container>
                      <Image fluid src={DisposalImg} />
                    </Container>
                  </TabBody>
                </Tab>
              </StyledTabs>
            </TabsContainer>
          </Col>
          <Col lg={5} className="d-flex flex-column justify-content-center">
            <SubHeader color={siteDarkBlue} className="pt-4">
              You Should Know:
            </SubHeader>

            <ListContainer>
              <ListItem>
                Epinephrine is generally a safe and effective treatment when
                used as directed.
              </ListItem>
              <ListItem>
                While there may be some side effects, the benefits far outweigh
                the risks in a life-threatening situation.
              </ListItem>
              <ListItem>
                Good Samaritan Laws protect those who administer epinephrine in
                an emergency, ensuring you are legally covered when helping
                someone in need.
              </ListItem>
            </ListContainer>
          </Col>
        </Row>

        <SubHeader color={siteDarkBlue} className="mt-5">
          Want to Know More About Epinephrine?
        </SubHeader>

        <Container fluid className="p-4 text-center">
          <RoundedButton
            color={sitePurple}
            onClick={() => customRouter.append("/epinephrine-faq")}
          >
            Epinephrine FAQ: What You Need to Know &gt;
          </RoundedButton>
        </Container>
      </PageContainer>
    </>
  );
};

export default Epinephrine;
