import React from "react";
import { StyledLink } from "./styled.components";
import { SubHeader, BodyText } from "../../pages/shared.styled.components";
import { siteDarkGreen } from "../../providers/Theme";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

export interface InTheNewsProps {
  title: string;
  text: string;
  action: Function;
  imageProps?: {
    src: string;
    orientation: "left" | "right";
  };
}

const NewsItem: React.FC<InTheNewsProps> = ({
  title,
  text,
  action,
  imageProps
}) => {
  const middleColSize = imageProps ? 8 : 12;

  const imageCol = () =>
    imageProps && (
      <Col className="d-flex flex-wrap align-items-center mb-3">
        <Image fluid src={imageProps.src} />
      </Col>
    );
  return (
    <Row>
      {imageProps?.orientation === "left" && imageCol()}
      <Col md={middleColSize} className="my-auto">
        <SubHeader color={siteDarkGreen}>{title}</SubHeader>
        <BodyText>
          {text}{" "}
          <StyledLink onClick={() => action()}>Learn More &gt;</StyledLink>
        </BodyText>
      </Col>
      {imageProps?.orientation === "right" && imageCol()}
    </Row>
  );
};

export default NewsItem;
