import React from "react";
import TriggerFoodsImg from "../../assets/trigger-foods.png";
import Image from "react-bootstrap/Image";
import { ImageContainer } from "./styled.components";
import {
  PageContainer,
  Header,
  BodyText,
  SubHeader,
  StyledSpan,
  JumbotronSubHeader
} from "../shared.styled.components";
import {
  siteDarkBlue,
  siteDarkGreen,
  siteLightBlue,
  sitePurple
} from "../../providers/Theme";

const TriggerFoods: React.FC = () => {
  return (
    <PageContainer>
      <Header>Trigger Foods</Header>
      <BodyText>
        Trigger foods are particular foods or substances in a food that can
        cause a food allergy. There is a broad spectrum of allergens that can
        cause severe allergic reactions and anaphylaxis, not just food. These
        allergens range from insects, animals, plans, grasses, medicines, and
        many others.
      </BodyText>

      <SubHeader color={siteDarkBlue}>The Big Nine</SubHeader>

      <BodyText>
        The <b>nine leading causes of food allergies</b> identified by the U.S.
        Food and Drug Administration are milk, eggs, fish, shellfish, tree nuts,
        peanuts, wheat, soybeans, and sesame.
      </BodyText>

      <ImageContainer>
        <Image fluid src={TriggerFoodsImg} />
      </ImageContainer>

      <SubHeader color={siteDarkBlue} className="mb-4">
        Frequently Asked Questions
      </SubHeader>

      <JumbotronSubHeader>
        If you <StyledSpan color={siteLightBlue}>EAT</StyledSpan> a certain
        food, can an allergic reaction occur?
      </JumbotronSubHeader>
      <BodyText>
        Absolutely! Eating a trigger food can cause a person to have an allergic
        reaction.
      </BodyText>

      <JumbotronSubHeader>
        If you <StyledSpan color={sitePurple}>TOUCH</StyledSpan> a certain food,
        can an allergic reaction occur?
      </JumbotronSubHeader>
      <BodyText>
        If a person touches an object that has not been properly cleaned it
        could contain trace amounts of a trigger food and cause a local
        reaction. The likelihood that a person develops a severe allergic
        reaction or anaphylaxis is low.
      </BodyText>

      <JumbotronSubHeader>
        If you <StyledSpan color={siteDarkGreen}>SMELL</StyledSpan> or{" "}
        <StyledSpan color={siteDarkGreen}>BREATHE IN</StyledSpan> a certain
        food, can an allergic reaction occur?
      </JumbotronSubHeader>
      <BodyText>
        Shellfish or other allergens can become airborne and cause symptoms to
        develop.
      </BodyText>

      <BodyText>
        According to the American College of Allergy, Asthma & Immunology,
        outside of cases related to cooking shellfish, the chances of having a
        severe reaction to airborne allergens are very low.
      </BodyText>
    </PageContainer>
  );
};

export default TriggerFoods;
