import styled from "styled-components";
import Card from "react-bootstrap/Card";
import { siteDarkGreen } from "../../providers/Theme";

export const StyledCard = styled(Card).attrs({ className: "mb-5 mx-5" })`
  min-width: 200px;
  max-width: 350px;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledCardTitle = styled(Card.Title)`
  color: ${siteDarkGreen};
`;
