import React from "react";
import HerosClimbGroup from "../../assets/heros-climb-group.jpeg";
import CollaboratorsImg from "../../assets/collaborators-thumbnail.png";
import ConnectWithUsImg from "../../assets/connect-with-us-thumbnail.png";
import InTheNewsImg from "../../assets/in-the-news.png";
import NewsLetterArchiveImg from "../../assets/newsletter-archive-thumbnail.png";
import AboutUsCard from "../../components/AboutUsCard";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { AboutUsCardProps } from "../AboutUsCard/AboutUsCard";

type AboutUsPages =
  | "about-us"
  | "kyahs-story"
  | "collaborators"
  | "connect-with-us"
  | "in-the-news"
  | "newsletter-archive";

interface AboutUsPageNavProps {
  currentPage: AboutUsPages;
}

const aboutUsCards: AboutUsCardProps[] = [
  {
    src: HerosClimbGroup,
    title: "Kyah's Story",
    path: "/kyahs-story"
  },
  {
    src: CollaboratorsImg,
    title: "Collaborators",
    path: "/collaborators"
  },
  {
    src: ConnectWithUsImg,
    title: "Connect With Us",
    path: "/connect-with-us"
  },
  {
    src: InTheNewsImg,
    title: "In the News",
    path: "/in-the-news"
  },
  {
    src: NewsLetterArchiveImg,
    title: "Newsletter Archive",
    path: "/newsletter-archive"
  }
];

const AboutUsPageNav: React.FC<AboutUsPageNavProps> = ({ currentPage }) => {
  const cardsToRender = aboutUsCards.filter(
    card => !card.path.endsWith(currentPage)
  );

  return (
    <Row className="mb-4">
      <Container>
        <Row className="d-flex flex-wrap justify-content-start">
          {cardsToRender.map(card => (
            <AboutUsCard {...card} />
          ))}
        </Row>
      </Container>
    </Row>
  );
};

export default AboutUsPageNav;
