import React from "react";
import {
  Header,
  TopPageContainer,
  BodyText,
  ListContainer,
  ListItem,
  SubHeader
} from "../shared.styled.components";
import Container from "react-bootstrap/Container";
import { siteDarkBlue } from "../../providers/Theme";
import EpinephrineTypeSection from "../../components/EpinephrineTypeSection";
import EpiPenAndEpiPenJr from "../../assets/epinephrine-types/epipen-and-epipen-jr.jpg";
import AuviQ from "../../assets/epinephrine-types/auvi-q.png";
import GenericEpi from "../../assets/epinephrine-types/generic-epi.jpg";
import TevaEpi from "../../assets/epinephrine-types/teva-epi.png";
import GenericAdrenaclick from "../../assets/epinephrine-types/generic-adrenaclick.jpg";
import Neffy from "../../assets/epinephrine-types/neffy.png";

const EpinephrineTypes: React.FC = () => {
  return (
    <>
      <TopPageContainer>
        <Header>Types of Epinephrine Devices</Header>

        <BodyText>
          When it comes to managing severe allergic reactions (anaphylaxis),
          having the right epinephrine device on hand can be life-saving. There
          are several types of epinephrine delivery devices available today,
          each offering a unique method of administration to suit different
          needs. On this page, you'll find detailed information about the
          various <b>epinephrine auto-injectors</b>, <b>nasal sprays</b>, and
          other devices currently on the market.
        </BodyText>

        <BodyText className="mb-0">
          We've provided a comprehensive overview, including:
        </BodyText>
        <ListContainer title="bla">
          <ListItem>Device options and manufacturers</ListItem>
          <ListItem>Available strengths and dosage instructions</ListItem>
          <ListItem>
            Training resources to help you use the devices correctly
          </ListItem>
          <ListItem>
            Discounts and patient assistance programs to help reduce costs
          </ListItem>
        </ListContainer>

        <BodyText>
          Whether you're exploring epinephrine options for yourself or a loved
          one, this page will help you make an informed decision about which
          device is best for your emergency preparedness. Please note that this
          may not contain all available options.
        </BodyText>

        <BodyText>
          Before using any epinephrine device, it is crucial to consult with
          your healthcare provider to determine which option is best suited to
          your individual needs. Your doctor can help you understand the correct
          dosage, potential side effects, and how to properly administer the
          device in an emergency.
        </BodyText>
      </TopPageContainer>

      <Container>
        <SubHeader color={siteDarkBlue}>Epinephrine Auto-Injectors</SubHeader>
      </Container>

      <EpinephrineTypeSection
        sectionTitle="EpiPen® and EpiPen Jr®"
        imageSrc={EpiPenAndEpiPenJr}
        bodyText="EPIPEN® and EPIPEN JR® Auto-Injectors are used to treat life- threatening, allergic emergencies in people who are at risk for or have a history of serious allergic emergencies. EPIPEN and EPIPEN JR are for immediate administration by you or your caregiver. They do not take the place of emergency medical care. You should get emergency help right away after using your EPIPEN or EPIPEN JR."
        manufacturer="Mylan"
        availableStrengths="0.15mg, and 0.3mg"
        trainingVideosLink="https://www.epipen.com/en/about-epipen-and-generic/how-to-use-epipen"
        moreInfoLink="https://www.epipen.com/en/"
        discounts={[
          {
            name: "EPIPEN Savings Card",
            link: "https://www.activatethecard.com/viatrisadvocate/epipen/welcome.html?_ga=2.1989941.507417750.1614981445-1095678745.1614981445"
          },
          {
            name: "Viatris Patient Assistance Program",
            link: "https://www.viatris.com/en-us/lm/united-states/patient-assistance-program"
          }
        ]}
      />

      <EpinephrineTypeSection
        sectionTitle="AUVI-Q®"
        imageSrc={AuviQ}
        bodyText="AUVI-Q® is a prescription medicine used to treat life-threatening allergic reactions, including anaphylaxis, in people who are at risk for or have a history of serious allergic reaction. This injector includes a talking feature which provides voice instructions to guide you through each step of the epinephrine injection process."
        manufacturer="kaléo"
        availableStrengths="0.1mg, 0.15mg, and 0.3mg"
        trainingVideosLink="https://www.auvi-q.com/about-auvi-q"
        moreInfoLink="https://www.auvi-q.com/"
        discounts={[
          {
            name: "ASPN Pharmacies",
            link: "https://www.auvi-q.com/get-auvi-q"
          },
          {
            name: "kaléo Patient Assistance Program Application",
            link: "https://www.auvi-q.com/pdf/AUVI-Q-Patient-Assistance-Program-Enrollment-Form.pdf"
          }
        ]}
      />

      <EpinephrineTypeSection
        sectionTitle="Epinephrine Injection, USP Auto-Injector - Authorized Generic of EpiPen® and EpiPen Jr®"
        imageSrc={GenericEpi}
        manufacturer="Mylan"
        availableStrengths="0.15mg, and 0.3mg"
        trainingVideosLink="https://www.epipen.com/en/about-epipen-and-generic/how-to-use-epipen"
        moreInfoLink="https://www.epipen.com/en/about-epipen-and-generic/when-to-use-epipen"
        discounts={[
          {
            name: "Epinephrine Injection, USP, Auto-Injector Savings Card",
            link: "https://www.activatethecard.com/viatrisadvocate/mygenericEAI/welcome.html?_ga=2.236993028.507417750.1614981445-1095678745.1614981445"
          },
          {
            name: "Viatris Patient Assistance Program",
            link: "https://www.viatris.com/en-us/lm/united-states/patient-assistance-program"
          }
        ]}
      />

      <EpinephrineTypeSection
        sectionTitle="Epinephrine Injection, USP Auto-Injector - Generic"
        imageSrc={TevaEpi}
        manufacturer="Teva Pharmaceutical Industries"
        availableStrengths="0.15mg, and 0.3mg"
        trainingVideosLink="https://www.tevaepinephrine.com/howtouse"
        moreInfoLink="https://www.tevaepinephrine.com/"
        discounts={[
          {
            name: "Teva Epinephrine Injection Savings Card",
            link: "https://www.tevaepinephrine.com/savings"
          }
        ]}
      />

      <EpinephrineTypeSection
        sectionTitle="Epinephrine Injection, USP Auto-Injector - Authorized Generic of AdrenaclickTM"
        imageSrc={GenericAdrenaclick}
        manufacturer="Amneal Pharmaceuticals"
        availableStrengths="0.15mg, and 0.3mg"
        trainingVideosLink="https://www.epinephrineautoinject.com/what-is-the-epinephrine-injector/how-to-use-the-epinephrine-injector/"
        moreInfoLink="https://www.epinephrineautoinject.com/"
        discounts={[
          {
            name: "EAI Savings Card",
            link: "https://www.epinephrineautoinject.com/wp-content/uploads/2022/06/EAI-Savings-Card-Sheet.pdf"
          }
        ]}
      />

      <Container className="mb-4">
        <SubHeader color={siteDarkBlue}>Other Epinephrine Devices</SubHeader>
      </Container>

      <EpinephrineTypeSection
        sectionTitle="neffy®"
        imageSrc={Neffy}
        bodyText="neffy® 2 mg is the first nasal spray used to treat life-threatening, allergic emergencies including anaphylaxis, in adults and children who weigh 66 lbs or more (30 kg or more), who are at risk for or have a history of serious allergic emergencies."
        manufacturer="ARS Pharmaceuticals"
        availableStrengths="2mg for individuals 66 pounds and more"
        trainingVideosLink="https://www.neffy.com/how-to-use/"
        moreInfoLink="https://www.neffy.com/"
        discounts={[
          {
            name: "Neffy Connect Support Program",
            link: "https://www.neffy.com/savings-and-support/"
          },
          {
            name: "ARS Pharma Patient Assistance Program",
            link: "https://ars-pharma.com/patient-assistance-programs/"
          }
        ]}
      />
    </>
  );
};

export default EpinephrineTypes;
