import styled from "styled-components";
import Pagination from "react-bootstrap/Pagination";
import { siteDarkGreen } from "../../providers/Theme";

export const StyledPagination = styled(Pagination)`
  justify-content: center;

  /* All page links (numbers, Prev, Next) share these styles */
  .page-item .page-link {
    background-color: #84d799; /* base green */
    color: #fff;
    border: none;
    border-radius: 8px;
    margin: 0 5px;
    width: 40px; /* fixed width */
    height: 40px; /* fixed height */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;
    font-size: 16px;
    font-weight: bold;
  }

  /* Remove any special border-radius on first or last child 
     (Bootstrap sometimes sets .rounded-start or .rounded-end).
  */
  .page-item:first-child .page-link,
  .page-item:last-child .page-link {
    border-radius: 8px !important;
  }

  /* Hover: slightly darker */
  .page-item .page-link:hover {
    background-color: #75c88a;
    color: #fff;
  }

  /* Active: darkest green */
  .page-item.active .page-link {
    background-color: ${siteDarkGreen};
    color: #fff;
  }

  /* Disabled state (e.g., Prev at page 1, Next at last page) */
  .page-item.disabled .page-link {
    opacity: 0.5;
    pointer-events: none;
  }

  /* Remove focus outline if desired */
  .page-item .page-link:focus {
    outline: none;
    box-shadow: none;
  }
`;
