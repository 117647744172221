import React from "react";
import { sitePurple } from "../../providers/Theme";
import LawsPolicyLegislationCard from "../../components/LawsPolicyLegislationCard";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import { HeaderSubText, StyledRow, StyledCardDeck } from "./styled.components";
import { PageContainer, Header } from "../shared.styled.components";

const LawsPolicyLegislation: React.FC = () => {
  return (
    <>
      <Jumbotron fluid className="bg-white py-4">
        <Container>
          <Header color={sitePurple}>
            Understand the Laws, Policy, and Legislation
          </Header>
          <HeaderSubText>
            The Kyah Rayne Foundation supports food allergy awareness and
            legislation. Learn about bills, acts, and laws that facilitate safer
            environments for individuals with food allergies and increase the
            availability of epinephrine.
          </HeaderSubText>
        </Container>
      </Jumbotron>

      <StyledRow>
        <PageContainer>
          <StyledCardDeck>
            <LawsPolicyLegislationCard
              title="Protecting Children with Food Allergies Act (S. 121)"
              link="https://www.congress.gov/bill/118th-congress/senate-bill/121?s=1&r=20"
              content="This bill aims to enhance safety in our schools by mandating food allergen training for all food service staff and equipping WIC personnel with information on food allergies and early introduction practices."
            />

            <LawsPolicyLegislationCard
              title="Adina Act: Labeling Medicine with the Top-Nine Allergens and Gluten"
              link="https://www.foodallergy.org/resources/2023-courage-congress-recap-and-resources"
              content="We support the right-to-know which allergens and gluten are in prescriptions. Medications designed to promote our health should not cause us harm."
            />

            <LawsPolicyLegislationCard
              title="Food Allergy Safety, Treatment, Education and Research Act of 2021 (FASTER ACT)"
              link="https://www.congress.gov/bill/117th-congress/senate-bill/578/text?q=%7B%22search%22%3A%5B%22S.+578%22%5D%7D&r=1&s=1"
              content="This bill broadens the definition of major food allergens for food-labeling requirements to specifically include sesame. Additionally, the Department of Health and Human Services is required to report on various aspects of food allergy research and data collection efforts."
            />

            <LawsPolicyLegislationCard
              title="Good Samaritan Laws"
              link="https://www.ncbi.nlm.nih.gov/books/NBK542176/"
              content="Good Samaritan laws offer legal protection to people who give reasonable assistance to those who are, or whom they believe to be, injured, ill, in peril, or otherwise incapacitated. The protection is intended to reduce bystanders' hesitation to assist, for fear of being sued or prosecuted for unintentional injury or wrongful death."
            />
            <LawsPolicyLegislationCard
              title="House Bill 1473"
              link="https://mgaleg.maryland.gov/mgawebsite/legislation/details/hb1473?ys=2018rs"
              content="Maryland House Bill establishing the Emergency Use Auto Injectable Epinephrine Program for Food Service facilities at Institutions of Higher Education in Arizona."
            />
            <LawsPolicyLegislationCard
              title="Kelsey Ryan Act"
              link="https://www.flrules.org/gateway/ruleNo.asp?id=6A-6.0251"
              content="Kelsey Ryan Act gives students the right to carry and self-administer epinephrine on school grounds if exposed to their specific life- threatening allergens."
            />
            <LawsPolicyLegislationCard
              title="Sabrina's Law"
              link="https://www.ola.org/en/legislative-business/bills/parliament-38/session-1/bill-3"
              content="Effective January 1, 2006, the law mandates that school boards require all principals to implement anaphylaxis plans. These plans must include strategies to minimize allergen exposure, procedures for communicating with parents, students, and staff about life- threatening allergies, and regular training for teachers and staff on managing such emergencies."
            />
            <LawsPolicyLegislationCard
              title="Bill 201 - The EPI Bill"
              link="https://docs.assembly.ab.ca/LADDAR_files/docs/bills/bill/legislature_30/session_1/20190521_bill-201.pdf"
              content="Bill 201, the Protection of Students With Life-Threatening Allergies Act, would require all publicly funded schools in Alberta, Canada, to have at least one epinephrine auto-injector on hand."
            />
            <LawsPolicyLegislationCard
              title="Food Safety Modernization Act (FSMA)"
              link="https://www.fda.gov/food/food-safety-modernization-act-fsma/full-text-food-safety-modernization-act-fsma#SEC112"
              content="US Food and Drug Administration (FDA) policy surrounding the development and provision of plans for individuals to manage the risk of food allergy and anaphylaxis in schools and early childhood education programs."
            />
            <LawsPolicyLegislationCard
              title="Substitute House Bill No. 5452"
              link="https://custom.statenet.com/public/resources.cgi?id=ID:bill:CT2018000H5452&ciq=ncsl7&client_md=43271fb5bdbaff6dae6610f907c3414e&mode=current_text"
              content="Connecticut act focused on developing guidelines for managing students with life-threatening food allergies in schools, while incorporating education on food allergies, dietary restrictions, cross- contamination, and allergen identification into the Healthy and Balanced Living Curriculum Framework and culinary arts programs."
            />
            <LawsPolicyLegislationCard
              title="School Access to Emergency Epinephrine Act (S. 1503)"
              link="https://www.congress.gov/bill/113th-congress/senate-bill/1503/text"
              content="Maryland House Bill establishing the Emergency Use Auto Injectable Epinephrine Program for Food Service facilities at Institutions of Higher Education in Arizona."
            />
            <LawsPolicyLegislationCard
              title="FDA Approves First Epinephrine Nasal Spray"
              link="https://www.fda.gov/news-events/press-announcements/fda-approves-first-nasal-spray-treatment-anaphylaxis"
              content="Neffy is a nasal spray developed as an epinephrine delivery system to treat severe allergic reactions, including anaphylaxis. Unlike traditional epinephrine auto-injectors like EpiPen, which are injected into the thigh, Neffy allows the user to administer epinephrine intranasally, making it a needle-free option."
            />
            <LawsPolicyLegislationCard
              title="New York State Legislature passed A.6425A - O'Donnell & S.7114A - Rivera"
              link="https://nyassembly.gov/leg/?term=2023&bn=S07114"
              content="Requires health insurance plans to provide coverage for epinephrine auto-injector devices; caps the cost to an insured at $100 per year."
            />
            <LawsPolicyLegislationCard
              title='"EpiPen" Act - H.R. 6965'
              link="https://www.congress.gov/bill/118th-congress/house-bill/4263/text"
              content="Mandates that educational food service establishments stock epinephrine auto- injectors for emergency anaphylaxis treatment. Promotes staff training in recognizing and treating allergic reactions, in partnership with healthcare professionals. The bill provides liability protection for good- faith epinephrine administration and requires data collection on anaphylactic incidents to improve safety protocols."
            />
            <LawsPolicyLegislationCard
              title="Elijah's Law"
              link="https://aafa.org/wp-content/uploads/2022/08/elijahs-law-fact-sheet.pdf"
              content="On September 12, 2019, Elijah's Law was signed into law in Elijah's home state of New York. The law requires all child care programs to follow state food allergy guidelines to prevent, recognize and respond to life-threatening anaphylactic reactions. This law was the result of an initiative known as “Elijah's Echo.” Elijah's Law in New York was the first of its kind in the United States"
            />
            <LawsPolicyLegislationCard
              title="Protecting Children with Food Allergies Act H.R. 9550"
              link="https://trackbill.com/bill/us-congress-house-bill-9550-protecting-children-with-food-allergies-act/2563648/"
              content="This bill requires the food and nutrition programs of the Department of Agriculture (USDA), such as the National School Lunch Program, to include training requirements, training opportunities, and other educational resources that address food allergies."
            />
          </StyledCardDeck>
        </PageContainer>
      </StyledRow>
    </>
  );
};

export default LawsPolicyLegislation;
