import React from "react";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import { siteDarkGreen } from "../../providers/Theme";
import Logo from "../../assets/kyahrayne-fullcolor-tm.png";
import SocialMediaIcon from "../../components/SocialMediaIcon";
import Address from "../../components/Address";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import { ContactInfoContainer, StyledLightButton } from "./styled.components";
import {
  PageContainer,
  Header,
  BodyText,
  SubHeader,
  StyledJumbotron
} from "../shared.styled.components";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube
} from "react-icons/fa";
import { INSTAGRAM_URL } from "../../constants";
import AboutUsPageNav from "../../components/AboutUsPageNav";
import DonateButton from "../../components/DonateButton";

const ConnectWithUs: React.FC = () => {
  const customRouter = useCustomRouter();

  const appendPath = (path: string) => {
    customRouter.append(path);
  };

  return (
    <>
      <PageContainer>
        <Row>
          <Col lg={6} md={7} sm={12}>
            <Header color={siteDarkGreen}>Connect With Us</Header>
            <ContactInfoContainer>
              <SubHeader>Get In Touch</SubHeader>
              <BodyText>Email: outreach@kyahraynefoundation.org</BodyText>
              <SubHeader>Follow Us on Social Media</SubHeader>
              <Row className="pl-3 pb-3 pt-2">
                <SocialMediaIcon
                  FaIcon={FaFacebookF}
                  link="https://www.facebook.com/Kyah-Rayne-Foundation-105676284120626"
                />

                <SocialMediaIcon FaIcon={FaInstagram} link={INSTAGRAM_URL} />

                <SocialMediaIcon
                  FaIcon={FaTwitter}
                  link="https://twitter.com/KyahRayne"
                />

                <SocialMediaIcon
                  FaIcon={FaLinkedinIn}
                  link="https://www.linkedin.com/company/kyah-rayne-foundation"
                />

                <SocialMediaIcon
                  FaIcon={FaYoutube}
                  link="https://www.youtube.com/channel/UCxf-Wwjgu0sqDEP2kyfTUpQ"
                />
              </Row>
              <SubHeader>Donations can be mailed to:</SubHeader>
              <Address />

              <div className="mt-3">
                <DonateButton />
              </div>
            </ContactInfoContainer>
          </Col>

          <Col className="d-flex flex-wrap align-items-center">
            <Image fluid src={Logo} />
          </Col>
        </Row>
      </PageContainer>

      <StyledJumbotron className="mb-0 py-5" backgroundColor={siteDarkGreen}>
        <Container className="text-center">
          <h1>
            <b>Meet Our Leadership</b>
          </h1>

          <Row className="pt-4">
            <Col>
              <StyledLightButton
                onClick={() => appendPath("/strategy-team")}
                color={siteDarkGreen}
              >
                Strategy <br /> Team
              </StyledLightButton>
            </Col>
            <Col>
              <StyledLightButton
                onClick={() => appendPath("/medical-advisory-board")}
                color={siteDarkGreen}
              >
                Medical Advisory Board
              </StyledLightButton>
            </Col>
            <Col>
              <StyledLightButton
                onClick={() => appendPath("/intern-team")}
                color={siteDarkGreen}
              >
                Intern <br /> Team
              </StyledLightButton>
            </Col>
          </Row>
        </Container>
      </StyledJumbotron>

      <AboutUsPageNav currentPage="connect-with-us" />
    </>
  );
};

export default ConnectWithUs;
