import styled from "styled-components";
import { BODY_FONT_SIZE } from "../../pages/shared.styled.components";

export const StyledLink = styled.a`
  font-size: ${BODY_FONT_SIZE};
  font-weight: bold;
  color: ${props => props.color};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: ${props => props.color};
  }
`;

export const DiscountLink = styled.a`
  font-size: ${BODY_FONT_SIZE};
  color: black;

  &:hover {
    cursor: pointer;
    color: black;
  }
`;
