import React from "react";
import DonateForm from "../DonateForm";
import { PayPalButton, PayPalImage } from "./styled.components";

const DonateButton: React.FC = () => {
  return (
    <DonateForm
      triggerElement={
        <PayPalButton>
          <PayPalImage />
          Donate with PayPal
        </PayPalButton>
      }
    />
  );
};

export default DonateButton;
