import React from "react";
import TrainingMaterials from "../TrainingMaterials/TrainingMaterials";
import EpiFirstEpiFastPoster from "../../assets/epi-first-epi-fast-poster.pdf";
import EpiFirstEpiFastPosterThumbnail from "../../assets/epi-first-epi-fast-poster-thumbnail.png";
import FareEmergencyActionPlanThumbnail from "../../assets/fare-emergency-care-plan-thumbnail.png";
import FareEmergencyActionPlan from "../../assets/fare-emergency-care-plan.pdf";

const TrainingMaterialsForSchools: React.FC = () => {
  return (
    <TrainingMaterials
      header="Posters"
      trainingMaterials={[
        {
          text: "Epi FIRST, Epi FAST, ReACT.",
          subText:
            "Use this poster to help you and others remember how to respond to anaphylaxis.",
          file: EpiFirstEpiFastPoster,
          fileName: "epi-first-epi-fast.pdf",
          thumbnail: EpiFirstEpiFastPosterThumbnail
        },
        {
          text: "Download a Food Allergy Action Plan FAAP) poster.",
          subText:
            "Post it in your office, cafe, restaurant, home, school classroom. Be a proponent of fast and efficient food allergy awareness and care.",
          file: FareEmergencyActionPlan,
          fileName: "food-allergy-action-plan.pdf",
          thumbnail: FareEmergencyActionPlanThumbnail
        }
      ]}
    />
  );
};

export default TrainingMaterialsForSchools;
