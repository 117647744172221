import React from "react";
import CrampingImg from "../../../src/assets/cramping.png";
import {
  PageContainer,
  Header,
  BodyText,
  ListContainer,
  ListItem,
  StyledJumbotron,
  SubHeader,
  JumbotronSubHeader,
  StyledBold
} from "../shared.styled.components";
import { StyledContainer, ImageContainer } from "./styled.components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { siteDarkBlue } from "../../providers/Theme";

const FoodAllergy101: React.FC = () => {
  return (
    <PageContainer>
      <Header>Food Allergy 101</Header>

      <BodyText>
        A food allergy reaction occurs when your immune system overreacts to
        what should be a harmless food protein - an allergen. When you have a
        food allergy, your immune system identifies it as a danger and triggers
        a protective response. Food allergies are potentially life-threatening
        and life-altering. Anyone who has a food allergy can have a severe
        allergic reaction to food.
      </BodyText>

      <BodyText>
        The <b>nine leading causes of food allergies</b> eggs, fish, shellfish,
        tree nuts, peanuts, wheat, soybeans, and sesame. About{" "}
        <b>90 percent of allergic reactions</b> come from these eight foods
        alone: Milk, eggs, peanuts, tree nuts, soy, wheat, fish and shellfish.
      </BodyText>

      <StyledJumbotron backgroundColor="white">
        <StyledContainer className="p-4">
          <JumbotronSubHeader className="text-center">
            FOOD ALLERGY FACTS AND STATISTICS
          </JumbotronSubHeader>

          <ListContainer>
            <ListItem>
              Approximately <StyledBold>33 million</StyledBold> people in the
              United States have at least one food allergy.
            </ListItem>
            <ListItem>
              <StyledBold>1 in 10 adults</StyledBold> have a food allergy and
              more than half (51%) have experienced a severe reaction.
            </ListItem>
            <ListItem>
              <StyledBold>1 in 13 children</StyledBold> have a food allergy and
              42% have experienced a severe reaction.
            </ListItem>
            <ListItem>
              Every ten seconds, a severe allergic reaction to food sends
              someone to the emergency room.
            </ListItem>
            <ListItem>
              <StyledBold>Every 10 second</StyledBold>s a food allergy reaction
              sends a patient to the emergency room
            </ListItem>
            <ListItem>
              Food allergies add <StyledBold>$25 billion</StyledBold> in cost to
              the American economy. The average family spends over $4100 per
              food-allergic child in extra costs each year.
            </ListItem>
            <ListItem>
              Only <StyledBold>1 in 20</StyledBold> with a convincing food
              allergy have a doctor-confirmed diagnosis. Less than a quarter
              (24%) with a food allergy report a current epinephrine
              prescription.
            </ListItem>
            <ListItem>
              According to the American College of Allergy, Asthma, &
              Immunology, food allergy symptoms can appear at{" "}
              <StyledBold>any age</StyledBold>, even as an adult you can develop
              an allergy to foods you've eaten for years with no previous
              reaction
            </ListItem>
            <ListItem>
              You can develop an allergy to foods you've eaten for years with no
              previous reaction
            </ListItem>
          </ListContainer>
        </StyledContainer>
      </StyledJumbotron>

      <SubHeader color={siteDarkBlue}>
        Food Allergies versus Food Intolerance
      </SubHeader>

      <Row>
        <Col xs={12} lg={7} className="my-auto">
          <BodyText>
            A food allergy occurs when the immune system reacts to a food which
            is usually harmless while a food intolerance occurs when the body
            has a chemical reaction to eating a particular food or drink. The
            symptoms (nausea, vomiting, cramping and diarrhea) for mild to
            moderate food allergy or intolerance may sometimes be similar, but{" "}
            <StyledBold>
              food intolerance does not involve the immune system and does not
              cause severe allergic reactions (anaphylaxis)
            </StyledBold>
            . If you have a true food allergy, even a tiny amount of food may
            trigger an allergic reaction.
          </BodyText>
        </Col>
        <Col xs={12} lg={5} className="d-flex flex-wrap align-items-center">
          <ImageContainer>
            <Image className="pb-4" fluid src={CrampingImg} />
          </ImageContainer>
        </Col>
      </Row>

      <SubHeader color={siteDarkBlue}>Food Allergies on are the Rise</SubHeader>

      <BodyText>
        The rise in allergies and asthma may be due to an increase in airborne
        pollens, climate changes that trigger a rise in pollen levels, the
        energy-proofing of indoor homes and work spaces, urban air pollution, or
        the overuse of antibiotics. Factors such as hygiene and lack of exposure
        to microbial factors, composition of the intestinal microbiota, diet,
        obesity, Vitamin D, and environmental chemical exposure have all been
        proposed to contribute to this alarming rise in the rate of food allergy
        in countries with a Westernized lifestyle.
      </BodyText>

      <BodyText>
        U.S. Centers for Disease Control and Prevention shows that food
        allergies in children have increased approximately{" "}
        <StyledBold>50%</StyledBold> between 1997 and 2011, now affecting{" "}
        <StyledBold>1 in 13 children</StyledBold> in the United States. This
        translates to roughly{" "}
        <StyledBold>two students in every classroom</StyledBold>.
      </BodyText>
    </PageContainer>
  );
};

export default FoodAllergy101;
