import React from "react";
import Card from "react-bootstrap/Card";
import { goToExternalUrl } from "../../utils";
import { StyledCard, StyledCardTitle } from "./styled.components";

interface DonorCardProps {
  title: string;
  image: string;
  link?: string;
}

const DonorCard: React.FC<DonorCardProps> = ({ title, image, link }) => {
  return (
    <StyledCard onClick={() => link && goToExternalUrl(link)} className="pb-4">
      <StyledCardTitle>{title}</StyledCardTitle>
      <Card.Img variant="bottom" src={image} />
    </StyledCard>
  );
};

export default DonorCard;
