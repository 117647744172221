import React from "react";
import {
  Header,
  TopPageContainer,
  SubHeader,
  BodyText,
  JumbotronSubHeader,
  ListContainer,
  ListItem,
  StyledBold,
  RoundedButton
} from "../shared.styled.components";
import Container from "react-bootstrap/Container";
import { siteDarkBlue } from "../../providers/Theme";

const HowToGetEpinephrine: React.FC = () => {
  const goToExternalUrl = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <TopPageContainer>
        <Header>How to Get Epinephrine</Header>
        <i>
          <SubHeader className="font-weight-normal">For Personal Use</SubHeader>
        </i>

        <SubHeader className="mt-4">Talk to Your Doctor</SubHeader>

        <BodyText>
          The first step in getting epinephrine is to consult with your
          healthcare provider. Your doctor will assess your allergy risk,
          discuss your medical history, and determine whether you need an
          epinephrine auto-injector or other forms of epinephrine.
        </BodyText>

        <JumbotronSubHeader>Why You Need a Prescription:</JumbotronSubHeader>

        <BodyText>
          Epinephrine is classified as a prescription medication, which means
          you cannot buy it over the counter. A doctor's prescription is
          required to obtain any form of epinephrine, whether it's an
          auto-injector, nasal spray, or injectable syringe.
        </BodyText>

        <BodyText className="mb-0">
          During your consultation, make sure to:
        </BodyText>

        <ListContainer>
          <ListItem>
            Discuss any <b>allergies</b> or past reactions that might require
            epinephrine.
          </ListItem>
          <ListItem>
            Ask your doctor about which epinephrine device is right for you.
            Options include EpiPen, Auvi-Q, Neffy (nasal spray), and others.
          </ListItem>
          <ListItem>
            Learn how to use the device correctly to ensure you are prepared in
            an emergency.
          </ListItem>
        </ListContainer>

        <SubHeader className="mt-4">Get Your Prescription</SubHeader>

        <BodyText className="mb-0">
          Once your doctor determines that you need epinephrine, they will
          provide a prescription for the appropriate device. Your prescription
          will specify:
        </BodyText>

        <ListContainer>
          <ListItem>
            The type of device (e.g., auto-injector or nasal spray).
          </ListItem>
          <ListItem>
            The dose of epinephrine (usually 0.3 mg for adults or 0.15 mg for
            children).
          </ListItem>
          <ListItem>
            Refill information: Epinephrine devices should be replaced regularly
            (typically every 12-18 months) to ensure they are functional.
          </ListItem>
        </ListContainer>

        <BodyText>
          Your doctor may also recommend carrying two devices in case one fails,
          or you need to administer a second dose in an emergency.
        </BodyText>

        <SubHeader className="mt-4">Fill Your Prescription</SubHeader>

        <BodyText className="mb-0">
          Once you have a prescription, you can fill it at your local pharmacy
          or online pharmacy. Here's what to keep in mind when filling your
          prescription:
        </BodyText>

        <ListContainer>
          <ListItem>
            <b>Insurance Coverage:</b> Most insurance plans cover epinephrine
            auto-injectors, though costs may vary depending on your plan and the
            device you choose. Check with your insurance provider to find out
            what's covered and your out- of-pocket costs.
          </ListItem>
          <ListItem>
            <b>Out-of-Pocket Costs:</b> If you don't have insurance or your plan
            doesn't fully cover epinephrine, the cost can be high, ranging from
            $300 to $700 for a two-pack of auto-injectors. Be sure to ask your
            pharmacist about generic versions or lower-cost alternatives.
          </ListItem>
          <ListItem>
            <b>Online Pharmacies:</b> You may also have the option to order your
            device from an online pharmacy. Be sure to verify the legitimacy of
            the website before making any purchase.
          </ListItem>
        </ListContainer>

        <BodyText>
          <StyledBold color={siteDarkBlue}>
            Important: Always check expiration dates.
          </StyledBold>{" "}
          Epinephrine devices have a shelf life of around 12-18 months, so be
          sure to replace expired devices promptly.
        </BodyText>

        <SubHeader className="mt-4">
          Explore Discount Programs and Financial Assistance
        </SubHeader>

        <BodyText className="mb-0">
          Epinephrine devices can be expensive, but there are programs available
          to help reduce costs.
        </BodyText>

        <ListContainer>
          <ListItem>
            <b>Manufacturer Discounts:</b> Many manufacturers, such as Mylan
            (EpiPen) and Kaleo (Auvi-Q), offer patient assistance programs or
            co-pay savings cards that can significantly lower the cost. These
            programs may reduce the price to as little as $0 to $50 per device.
          </ListItem>
          <ListItem>
            <b>Neffy:</b> The Neffy nasal spray also offers financial assistance
            programs to make the medication more affordable. Be sure to check
            with the manufacturer for the latest information on savings.
          </ListItem>
          <ListItem>
            <b>State and Federal Assistance:</b> Depending on your income and
            insurance situation, you may qualify for government assistance
            programs or charitable organizations that help cover the cost of
            epinephrine devices.
          </ListItem>
        </ListContainer>

        <Container fluid className="p-4 text-center">
          <RoundedButton
            color={siteDarkBlue}
            onClick={() =>
              goToExternalUrl(
                "https://docs.google.com/spreadsheets/d/1t3-cmiKzSRqIV3_SXy3fcqlqqgbKi47CwriSg3o_NM0/edit?gid=0#gid=0"
              )
            }
          >
            Epinephrine Discount Resources
          </RoundedButton>
        </Container>
      </TopPageContainer>
    </>
  );
};

export default HowToGetEpinephrine;
