import React from "react";
import AllergicLivingLogo from "../../assets/allergic-living-logo.png";
import FareLogo from "../../assets/collaborators/fare-logo.png";
import SpokinLogo from "../../assets/collaborators/spokin-logo.png";
import UOfACollegeOfPublicHealthLogo from "../../assets/collaborators/ua-coph.png";
import WRPHTCLogo from "../../assets/collaborators/WRPHTC.png";
import AANLogo from "../../assets/collaborators/AAN-logo.png";
import CertistarLogo from "../../assets/collaborators/certistar-logo.png";
import PCDHLogo from "../../assets/collaborators/PCDH-logo.png";
import MCDPHLogo from "../../assets/collaborators/maricopa-public-health.png";
import EqualEatsLogo from "../../assets/collaborators/equal-eats-logo.png";
import FoxRestaurantConceptLogo from "../../assets/collaborators/fox-restaurant-concepts-logo.png";
import CampNotAWheezeLogo from "../../assets/collaborators/camp-not-a-wheeze-logo.png";
import NeffyLogo from "../../assets/collaborators/neffy-in-schools.svg";
import UAHSResearchCenterLogo from "../../assets/collaborators/uahs_asthma-airway-disease-research-center.png";
import AzAsthmaAndAllergyLogo from "../../assets/collaborators/az-asthma-and-allergy.png";
import PaCommonwealthLogo from "../../assets/collaborators/pa-commonwealth-logo.svg";
import LaraLogo from "../../assets/collaborators/lara.svg";
import NationalAssociationSchoolOfNursesLogo from "../../assets/collaborators/national-association-school-of-nurses.png";
import CollaboratorCard from "../../components/CollaboratorCard";
import CardDeck from "react-bootstrap/CardDeck";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { siteDarkGreen } from "../../providers/Theme";
import {
  PageContainer,
  Header,
  StyledVideo
} from "../shared.styled.components";
import AboutUsPageNav from "../../components/AboutUsPageNav";
import Donors from "../../components/Donors";
import { VideoContainer } from "./styled.components";

const Collaborators: React.FC = () => {
  return (
    <PageContainer>
      <Container className="text-center my-5">
        <Header color={siteDarkGreen}>Meet Our Collaborators</Header>
      </Container>

      <CardDeck className="d-flex flex-wrap justify-content-center">
        <CollaboratorCard
          src={UOfACollegeOfPublicHealthLogo}
          title="Mel & Enid Zuckerman College of Public Health"
          content="The UA Zuckerman College of Public Health provides both undergraduate and graduate education in population-based health disciplines designed to prepare students for professional and academic public-health careers."
          link="https://publichealth.arizona.edu/"
        />

        <CollaboratorCard
          src={MCDPHLogo}
          title="Maricopa County Department of Public Health"
          content="Our mission at Maricopa County Public Health is to protect and promote the health and well-being of all of our residents and visitors."
          link="https://www.maricopa.gov/5302/Public-Health"
        />

        <CollaboratorCard
          src={PCDHLogo}
          title="Pima County Health Department"
          content="The Pima County Health Department is dedicated to help the residents of Pima County achieve and maintain an optimal level of wellness."
          link="https://webcms.pima.gov/government/health_department/"
        />

        <CollaboratorCard
          src={WRPHTCLogo}
          title="Western Region Public Health Training Center"
          content="The Western Region Public Health Training Center is a Continuing Professional Education (CPE) Accredited Provider with the Commission on Dietetic Registration (CDR)."
          link="https://wrphtc.arizona.edu/"
        />

        <CollaboratorCard
          src={FareLogo}
          title="FARE"
          content="FARE works to enhance the access of individuals with food allergies to safe food, effective emergency treatment and accurate health information."
          link="https://www.foodallergy.org/"
        />

        <CollaboratorCard
          src={AllergicLivingLogo}
          title="Allergic Living"
          content="The authoritative voice on food allergy news."
          link="https://www.allergicliving.com/"
        />

        <CollaboratorCard
          src={NeffyLogo}
          title="Neffy"
          content="Neffy 2 mg is a nasal spray used to treat life-threatening, allergic emergencies including anaphylaxis. neffy is the first- and-only needle-free epinephrine nasal spray for serious allergic reactions."
          link="https://www.neffy.com/community-programs/"
        />

        <CollaboratorCard
          src={CertistarLogo}
          title="Certistar"
          content="Take the guesswork out of food allergies. A mission to protect and improve the safety and dining experience of men, women and children vulnerable to food allergens."
          link="https://certistar.com/"
        />

        <CollaboratorCard
          src={SpokinLogo}
          title="Spokin"
          content="Meet the best food allergy app ever. Spokin saves you time to make life with food allergies easier."
          link="https://www.spokin.com/"
        />

        <CollaboratorCard
          src={AANLogo}
          title="Allergy & Asthma Network"
          content="Allergy & Asthma Network unites and advocates on behalf of 60 million Americans with asthma, allergies and related conditions."
          link="https://allergyasthmanetwork.org/"
        />

        <CollaboratorCard
          src={FoxRestaurantConceptLogo}
          title="Fox Restaurant Concepts"
          content="Fox Restaurant Concepts believes the best stories are shared over food. We challenge ourselves to find ways to make our best ideas even better and our people and guests are at the heart of all we do."
          link="https://www.foxrc.com/"
        />

        <CollaboratorCard
          src={EqualEatsLogo}
          title="Equal Eats"
          content="Our mission is to provide solutions and tools to help people living with dietary restrictions communicate with the food service industry effectively, accurately, and conveniently."
          link="https://equaleats.com/"
        />

        <CollaboratorCard
          src={UAHSResearchCenterLogo}
          title="Asthma Disease & Research Center"
          content="Asthma & Airway Disease Research Center is an interdisciplinary Center of Excellence at the University Of Arizona dedicated to research, clinical care, medical education, and community service in the areas of adult and pediatric pulmonary medicine."
          link="https://airways.uahs.arizona.edu/"
        />

        <CollaboratorCard
          src={CampNotAWheezeLogo}
          title="Camp Not-A-Wheeze"
          content="Celebrating more than 35 years, Camp Not-A-Wheeze is the premiere camp for children with asthma or allergic conditions in the state of Arizona. In a traditional camp setting asthma and allergy education is integrated with recreational camp activities. This empowers children to exercise control over their allergy or chronic lung condition."
          link="https://www.campnotawheeze.org"
        />

        <CollaboratorCard
          src={NationalAssociationSchoolOfNursesLogo}
          title="National Association of School Nurses"
          content="NASN works to optimize student health, safety and learning, it is the position of the National Association of School Nurses that a professional registered school nurse be present in every school all day, every day."
          link="https://www.nasn.org/home"
        />

        <CollaboratorCard
          src={LaraLogo}
          title="State of Michigan Department of Licensing and Regulatory Affairs"
          content="To be national leaders that partner with people and businesses to improve the lives of Michigan residents through an engaged and inclusive workforce."
          link="https://www.michigan.gov/lara"
        />

        <CollaboratorCard
          src={PaCommonwealthLogo}
          title="Pennsylvania Department of Health Bureau of EMS"
          content="The Department of Health is the lead agency for emergency medical services (EMS) in the commonwealth. The Department’s Bureau of Emergency Medical Services is responsible for the statewide development and coordination of a comprehensive system to prevent and reduce premature death and disability."
          link="https://www.pa.gov/agencies/health/healthcare-and-public-health-professionals/ems/epi-pen-training.html"
        />

        <CollaboratorCard
          src={AzAsthmaAndAllergyLogo}
          title="Arizona Allergy and Asthma"
          content="Mission is to provide exceptional care and expertise to patients suffering from allergies, immunological disorders, and asthma. Our team of highly skilled physician specialists, all board certified by the American Board of Allergy and Immunology, is dedicated to improving the lives of our patients and serving as leaders in our field."
          link="https://azsneeze.com/"
        />
      </CardDeck>

      <Container fluid="md" className="text-center">
        <Header color={siteDarkGreen}>Our Impact</Header>
        <Row className="justify-content-center my-1">
          <Col>
            <VideoContainer>
              <StyledVideo
                className="embed-responsive-item"
                src="https://player.vimeo.com/video/1060206530"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
              />
            </VideoContainer>
          </Col>
        </Row>
      </Container>

      <Donors />

      <AboutUsPageNav currentPage="collaborators" />
    </PageContainer>
  );
};

export default Collaborators;
