import styled from "styled-components";
import { siteGray, sitePurple } from "../../providers/Theme";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { BODY_FONT_SIZE, RoundedButton } from "../shared.styled.components";

export const StyledCol = styled(Col).attrs({
  className: "d-flex flex-wrap mb-5"
})`
  background-color: ${siteGray};

  padding: 1.5rem;
`;

export const BorderCol = styled(Col)`
  border-bottom: none;
  padding-bottom: 25px;

  ${({ theme }) => theme.md`
    border-right: none;
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 1.5rem;
  `}

  ${({ theme }) => theme.lg`
    border-right: 4px solid white;
  `}
`;

export const ColumnContainer = styled(Container)`
  width: 75%;
  font-weight: 300;
`;

export const JumbotronLink = styled.a`
  font-size: ${BODY_FONT_SIZE};
  color: white;
  text-decoration: underline;

  &:hover {
    color: white;
  }
`;

const styledButtonColors = `
  box-shadow: none;
  background-color: white !important;
  color: ${sitePurple} !important;
`;

export const StyledRoundedButton = styled(RoundedButton)`
  width: 100%;

  ${styledButtonColors}

  &:hover {
    ${styledButtonColors}
  }

  &:active {
    ${styledButtonColors}
  }

  &:focus {
    ${styledButtonColors}
  }

  &:visited {
    ${styledButtonColors}
  }
`;
