import React from "react";
import PageTitleBanner from "../../components/PageTitleBanner";
import BackgroundImgCard from "../../components/BackgroundImgCard";
import GetInvolvedBannerImg from "../../assets/get-involved-banner.png";
import LaysAndPolicyImg from "../../assets/laws-policy.png";
import InternshipsAndVolunteersImg from "../../assets/internships-and-volunteers.png";
import AdvocateForChangeImag from "../../assets/advocate-for-change.png";
import CardDeck from "react-bootstrap/CardDeck";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { sitePurple } from "../../providers/Theme";
import {
  StyledContainer,
  CardsRow,
  StyledText,
  StyledLink,
  SubText,
  StyledLightButton,
  GrayContainer
} from "./styled.components";
import {
  BodyText,
  StyledJumbotron,
  StyledBold,
  SubHeader,
  RoundedButton,
  JumbotronSubHeader
} from "../shared.styled.components";
import SSMPMaricopaCountyToolkit from "../../assets/toolkits/ssmp-maricopa-county-toolkit.pdf";
import SSMPPimaCountyToolkit from "../../assets/toolkits/ssmp-pima-county-toolkit.pdf";
import { goToExternalUrl, openDocumentInNewTab } from "../../utils";
import {
  MARICOPA_SSMP_ENROLLMENT_SITE,
  NATIONAL_EPICOURSE_SCHOOLS_LINK
} from "../../constants";
import NationalCourseToolkit from "../../assets/toolkits/national-kyahs-epi-course-for-schools-toolkit.pdf";

interface ArizonaEnrollmentRowProps {
  body: React.ReactElement;
  buttonProps: {
    link: string;
    text: string;
  };
}

const renderArizonaEnrollmentRow = (props: ArizonaEnrollmentRowProps) => {
  return (
    <Row className="mb-4">
      <Col xl={8}>{props.body}</Col>
      <Col xl={4} className="align-self-center text-center">
        <StyledLightButton
          onClick={() => goToExternalUrl(props.buttonProps.link)}
          color={sitePurple}
        >
          {props.buttonProps.text}
        </StyledLightButton>{" "}
      </Col>
    </Row>
  );
};

const GetInvolved: React.FC = () => {
  return (
    <>
      <PageTitleBanner
        title="Get Involved"
        content="Discover how you can Step In, Step Up. This section provides information on current food allergy laws and policies. Find out how you can use your voice to advocate for change in schools and at the state level."
        imageSrc={GetInvolvedBannerImg}
      />

      <CardsRow className="py-5">
        <StyledContainer>
          <CardDeck className="d-flex flex-wrap justify-content-center">
            <BackgroundImgCard
              src={LaysAndPolicyImg}
              path="/laws-policy-legislation"
              title="Understand the Laws, Policy, and Legislation"
            />
            <BackgroundImgCard
              src={InternshipsAndVolunteersImg}
              path="/internships-and-volunteers"
              title="Internships and Volunteers"
            />
            <BackgroundImgCard
              src={AdvocateForChangeImag}
              path="/advocate-for-change"
              title="Advocate for Change"
            />
          </CardDeck>
        </StyledContainer>
      </CardsRow>

      <Row className="d-flex flex-wrap align-items-center p-4">
        <Col sm={3}>
          <Container className="text-center">
            <h2>EAI Discount Programs</h2>
          </Container>
        </Col>
        <Col sm={9}>
          <Container className="py-5">
            <StyledText>
              High costs often prevent those with food allergies from being able
              to carry their own epinephrine auto-injectors (EAIs).{" "}
              <StyledLink
                href="https://docs.google.com/spreadsheets/d/1aBu_7zl4YLxHk-AghzA3wczUHhLWj3n-MHt7nJaswxw/edit?usp=sharing"
                target="_blank"
                color={sitePurple}
              >
                Click here
              </StyledLink>{" "}
              for a list of programs and discounts designed to lower the costs
              of EAIs.
            </StyledText>
          </Container>
        </Col>
      </Row>

      <StyledJumbotron className="mb-0" backgroundColor={sitePurple}>
        <Container>
          <h1 className="text-center">
            <b>Enroll Your Arizona School in a Stock Epinephrine Program</b>
          </h1>

          <BodyText>
            Arizona Stock Epinephrine Programs aim to equip school systems with
            guidelines and policies for obtaining stock albuterol inhalers and
            epinephrine auto-injectors. These resources are crucial for
            potentially saving the lives of students experiencing severe
            allergic reactions or respiratory distress.
          </BodyText>

          {renderArizonaEnrollmentRow({
            buttonProps: {
              text: "SSMP Enrollment Portal",
              link: MARICOPA_SSMP_ENROLLMENT_SITE
            },
            body: (
              <>
                <BodyText className="mb-1">
                  For <StyledBold>all Arizona counties EXCEPT</StyledBold> Pima
                  County, enroll in the Maricopa County Department of Public
                  Health{" "}
                  <StyledBold>
                    School Surveillance Medication Program (SSMP)
                  </StyledBold>
                  .
                </BodyText>
                <SubText>
                  Review our{" "}
                  <StyledLink
                    style={{ fontSize: "inherit" }}
                    color="white"
                    onClick={() =>
                      openDocumentInNewTab(SSMPMaricopaCountyToolkit)
                    }
                  >
                    Stock Surveillance Medication Program Toolkit
                  </StyledLink>{" "}
                  for easy enrollment instructions
                </SubText>
              </>
            )
          })}

          {renderArizonaEnrollmentRow({
            buttonProps: {
              text: "Pima County Enrollment Portal",
              link: "https://hd123.pima.gov/portal/apps/sites/#/epinephrine-stock-medication-portal"
            },
            body: (
              <>
                <BodyText className="mb-1">
                  For Arizona schools located in{" "}
                  <StyledBold>Pima County</StyledBold>, enroll in the Pima
                  County Health Department{" "}
                  <StyledBold>Epinephrine Stock Medication Program</StyledBold>.
                </BodyText>

                <SubText>
                  Review our{" "}
                  <StyledLink
                    style={{ fontSize: "inherit" }}
                    color="white"
                    onClick={() => openDocumentInNewTab(SSMPPimaCountyToolkit)}
                  >
                    Epinephrine Stock Medication Program Toolkit
                  </StyledLink>{" "}
                  for easy enrollment instructions
                </SubText>
              </>
            )
          })}
        </Container>
      </StyledJumbotron>

      <GrayContainer>
        <SubHeader className="mx-auto text-center pb-3">
          <StyledBold>Not an Arizona School?</StyledBold>
        </SubHeader>

        <SubHeader className="mx-auto text-center pb-3">
          <StyledBold>Take National Kyah's EPICourse for Schools</StyledBold>
        </SubHeader>

        <Container>
          <BodyText>
            National Kyah's EPICourse for Schools is a{" "}
            <StyledBold>free</StyledBold> comprehensive training program
            designed to educate school staff on anaphylaxis management, proper
            use of epinephrine auto-injectors, emergency protocols, and allergy
            awareness. This course includes state specific stock epinephrine
            laws and information to help you protect students with food
            allergies.
          </BodyText>
          <BodyText>
            Review our{" "}
            <StyledLink
              color="black"
              onClick={() => openDocumentInNewTab(NationalCourseToolkit)}
            >
              School Emergency Epinephrine Toolkit
            </StyledLink>{" "}
            for information on your state's laws.
          </BodyText>
          <div className="d-flex justify-content-center">
            <RoundedButton
              style={{ maxWidth: "650px" }}
              color={sitePurple}
              onClick={() => {
                goToExternalUrl(NATIONAL_EPICOURSE_SCHOOLS_LINK);
              }}
            >
              <JumbotronSubHeader>
                National Kyah's EPICourse for Schools
              </JumbotronSubHeader>
              <JumbotronSubHeader>Enroll Now</JumbotronSubHeader>
            </RoundedButton>
          </div>
        </Container>
      </GrayContainer>
    </>
  );
};

export default GetInvolved;
