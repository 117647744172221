import React from "react";
import { siteDarkGreen } from "../../providers/Theme";
import MedicalAdvisoryBoardCard from "../../components/MedicalAdvisoryBoardCard";
import DavidNotrica from "../../assets/medical-advisory-board/david-notrica.jpg";
import CindyBauer from "../../assets/medical-advisory-board/cindy-bauer.jpg";
import MichelleNotrica from "../../assets/strategy-team/michelle-notrica.jpg";
import BentleyBobrow from "../../assets/medical-advisory-board/bentley-bobrow.jpg";
import BenWright from "../../assets/medical-advisory-board/ben-wright.jpg";
import { PageContainer, Header } from "../shared.styled.components";

const MedicalAdvisoryBoard: React.FC = () => {
  return (
    <PageContainer>
      <Header color={siteDarkGreen}>Medical Advisory Board</Header>

      <div className="d-flex flex-wrap justify-content-center pt-5">
        <MedicalAdvisoryBoardCard
          src={CindyBauer}
          title="Cindy Salm Bauer, MD, FAAAAI"
          content={[
            "Division Chief, Allergy and Immunology, Phoenix Children's Hospital",
            "Co‐Director, Eosinophilic Gastrointestinal Disease Clinic"
          ]}
        />
        <MedicalAdvisoryBoardCard
          src={BentleyBobrow}
          title="Bentley J. Bobrow, MD, FACEP, FAHA"
          content={[
            "Department of Emergency Medicine at the University of Arizona College of Medicine - Tucson",
            "Co‐director of EMS research",
            "Associate director of the Arizona Emergency Medicine Research Center"
          ]}
        />
        <MedicalAdvisoryBoardCard
          src={MichelleNotrica}
          title="Michelle Notrica"
          content={[
            "Doctor of Pharmacy",
            "Attorney",
            "Public Health Consultant in International Health and Health Policy"
          ]}
        />
        <MedicalAdvisoryBoardCard
          src={DavidNotrica}
          title="David M Notrica MD, FACS, FAAP"
          content={[
            "Director of Trauma Pediatric Surgery, Phoenix Children's Hospital",
            "Trauma Medical Director Phoenix Children's Hospital",
            "Pediatric Surgery Fellowship Program Director Mayo Clinic/Phoenix Children's Hospital",
            "Professor of Child Health and Surgery University of Arizona College of Medicine Phoenix",
            "Associate Professor of Surgery Mayo Clinic"
          ]}
        />
        <MedicalAdvisoryBoardCard
          src={BenWright}
          title="Benjamin Wright"
          content={[
            "Consultant - Division of Allergy, Asthma, and Clinical Immunology, Mayo Clinic Arizona",
            "Physician - Division of Allergy and Immunology, Phoenix Children's",
            "Site Principal Investigator - Consortium of Eosinophilic Gastrointestinal Disease Researchers (CEGIR)",
            "Chair - Eosinophilic Gastrointestinal Disorders Subcommittee, American Academy of Allergy, Asthma, and Immunology (AAAAI)",
            "Clinical Assistant Professor of Child Health - Phoenix Children's",
            "Associate Professor of Medicine - Mayo Clinic Arizona"
          ]}
        />
      </div>
    </PageContainer>
  );
};

export default MedicalAdvisoryBoard;
