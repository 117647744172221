import styled from "styled-components";
import { siteDarkBlue, siteLightBlue } from "../providers/Theme";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";

export const BODY_FONT_SIZE = "18px";

export const PageContainer = styled(Container).attrs({ className: "mb-5" })``;

export const TopPageContainer = styled(PageContainer)`
  margin-bottom: 0 !important;
`;

export const Header = styled.h1.attrs({ className: "mt-4" })`
  color: ${props => props.color || siteDarkBlue};
  font-weight: 900;
`;

interface SubHeaderProps {
  color?: string;
}

export const SubHeader = styled.h3<SubHeaderProps>`
  font-weight: bold;
  ${props => props.color && `color: ${props.color}`};
`;

interface BodyTextProps {
  color?: string;
  className?: string;
}

export const BodyText = styled.p.attrs<BodyTextProps>(({ className }) => ({
  className: className ? className : "mb-4"
}))<BodyTextProps>`
  font-size: ${BODY_FONT_SIZE};
  color: ${({ color }) => color || "inherit"};
`;

export const ListContainer = styled.ul`
  word-wrap: break-word;
`;

export const OrderedListContainer = styled.ol`
  word-wrap: break-word;
`;

export const ListItem = styled.li`
  font-size: ${BODY_FONT_SIZE};
`;

export const StyledLink = styled.a`
  color: ${siteDarkBlue};
`;

interface StyledJumbotronProps {
  backgroundColor?: string;
  color?: string;
  marginBottom?: string;
}

export const StyledJumbotron = styled(Jumbotron)<StyledJumbotronProps>`
  background-color: ${props => props.backgroundColor || siteDarkBlue};
  color: ${props => props.color || "white"};
  padding: 2rem 1rem;
  border-radius: 0;
  ${props =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ""}
`;

interface StyledButtonProps {
  color: string;
}

export const LightButton = styled(Button).attrs({
  variant: "outline-light"
})<StyledButtonProps>`
  border: 3px solid;
  font-size: ${BODY_FONT_SIZE};
  padding: 5px 50px;
  border-radius: 0;

  &:hover {
    color: ${props => props.color};
  }
`;

const buttonStates = (color: string) => {
  return `
      background-color: ${color} !important;
      border: none !important;
      text-decoration: underline !important;
      outline: 0 !important;
      box-shadow: none;
      cursor: pointer !important;
  `;
};

export const RoundedButton = styled(Button)<StyledButtonProps>`
  background-color: ${props => props.color} !important;
  border-color: ${props => props.color} !important;
  border-radius: 1000px;
  border: none !important;
  padding: 0.625rem 1.875rem;
  font-size: 1.15rem;
  font-weight: bold;

  h3 {
    margin-bottom: 0px;
  }

  &:hover {
    ${props => buttonStates(props.color)}
  }

  &:active {
    ${props => buttonStates(props.color)}
  }
`;

export const JumbotronHeader = styled.h1.attrs({ className: "mx-auto mb-4" })`
  font-weight: bold;
`;

interface JumbotronSubHeaderProps {
  color?: string;
}

export const JumbotronSubHeader = styled.h4<JumbotronSubHeaderProps>`
  font-weight: bold;
  ${props => (props.color ? `color: ${props.color};` : "")}
`;

export const BlueLine = styled.hr`
  border: none;
  height: 5px;

  /* Set the hr color */
  color: ${siteLightBlue}; /* old IE */
  background-color: ${siteLightBlue}; /* Modern Browsers */
`;

interface StyledBoldProps {
  color?: string;
}

export const StyledBold = styled.b<StyledBoldProps>`
  color: ${props => props.color};
`;

interface StyledSpanProps {
  color?: string;
}

export const StyledSpan = styled.span<StyledSpanProps>`
  color: ${props => props.color};
`;

interface StyledVideoProps {
  src: string;
}

export const StyledVideo = styled.iframe.attrs<StyledVideoProps>(props => ({
  className: "embed-responsive-item",
  src: props.src,
  frameBorder: "0",
  allow: "autoplay; encrypted-media",
  allowFullScreen: true,
  title: "video"
}))`
  width: 100%;
  height: 100%;
`;
