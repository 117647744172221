import React from "react";
import KyahsEpicCourseForFoodServiceBanner from "../../assets/national-kyahs-epicourse-for-schools-banner.png";
import KyahsEpiCourseForSchoolsThumbnail from "../../assets/kyah-epicourse-for-schools.png";
import NationalCourseToolkitThumbnail from "../../assets/toolkits/national-kyahs-epi-course-for-schools-toolkit-thumbnail.png";
import NationalCourseToolkit from "../../assets/toolkits/national-kyahs-epi-course-for-schools-toolkit.pdf";
import { siteDarkBlue, siteLightBlue } from "../../providers/Theme";
import {
  BodyText,
  PageContainer,
  StyledBold,
  StyledSpan,
  StyledVideo,
  SubHeader
} from "../shared.styled.components";
import {
  NATIONAL_EPICOURSE_SCHOOLS_LINK,
  NationalEPICourseForSchoolsEnrollmentVideo
} from "../../constants";
import Image from "react-bootstrap/Image";
import EpiCourseForSchoolsVideoLibrary from "../../components/EpiCourseForSchoolsVideoLibrary";
import TrainingToolkitsForSchools from "../../components/TrainingToolkitsForSchools";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import { StyledLink } from "./styled.components";
import TrainingMaterialsForSchools from "../../components/TrainingMaterialsForSchools";
import JumbotronSplit from "../../components/JumbotronSplit";
import PageTitleBanner from "../../components/PageTitleBanner";

const openDocumentInNewTab = (file: string) => {
  window.open(file, "_blank");
};

const KyahsEpiCourseForSchools: React.FC = () => {
  const customRouter = useCustomRouter();

  const goToPath = (path: string) => {
    customRouter.push(path);
  };

  return (
    <>
      <PageTitleBanner
        title="National Kyah's EPICourse for Schools"
        titleTextColor={siteDarkBlue}
        imageSrc={KyahsEpicCourseForFoodServiceBanner}
      />

      <PageContainer>
        <SubHeader className="text-center my-4" color={siteLightBlue}>
          Learn More About Anaphylaxis, Its Causes, and the Proper Emergency
          Response
        </SubHeader>

        <BodyText>
          This section offers valuable learning resources, including videos on
          various topics related to food allergies, as well as downloadable
          graphics to help promote and guide food allergy response. These
          resources are designed to equip individuals with the knowledge needed
          to manage food allergies and respond effectively during an emergency.
        </BodyText>

        <BodyText>
          We invite everyone to take advantage of our free food allergy
          education courses. These courses provide important information on
          managing food allergies and responding to emergencies.
        </BodyText>

        <BodyText>
          <StyledBold>
            <StyledSpan color="red">For school personnel in ARIZONA</StyledSpan>
          </StyledBold>
          , please take Kyah's EPICourse for Arizona Schools. Kyah's EPICourse
          for Arizona Schools has been specifically created to educate Arizona
          school staff on the Maricopa County Department of Public Health School
          Surveillance Medication Program and the Pima County Health Department
          Stock Medication Program. The national course certificate{" "}
          <StyledBold>cannot</StyledBold> be used for the stock medication
          programs.
        </BodyText>
      </PageContainer>

      <JumbotronSplit
        title="Register for National Kyah's EPICourse for Schools"
        description="Register for National Kyah's EPICourse for Schools"
        bgColor={siteLightBlue}
        section1={{
          title: "National Kyah's EPICourse for Schools",
          buttonOptions: {
            text: "Register Now",
            link: NATIONAL_EPICOURSE_SCHOOLS_LINK
          },
          extraContent: <Image fluid src={KyahsEpiCourseForSchoolsThumbnail} />
        }}
        section2={{
          title: "Easy Enrollment Tutorial Video",
          extraContent: (
            <StyledVideo
              style={{ minHeight: "215px" }}
              src={NationalEPICourseForSchoolsEnrollmentVideo}
            />
          )
        }}
      />

      <PageContainer>
        <EpiCourseForSchoolsVideoLibrary />

        <TrainingToolkitsForSchools
          header="National Kyah's EPICourse for Schools Toolkit"
          toolkits={[
            {
              title: "National Kyah's EPICourse for Schools Toolkit",
              file: NationalCourseToolkit,
              thumbnail: NationalCourseToolkitThumbnail,
              body: (
                <>
                  <BodyText>
                    The national toolkit is designed to show the step-by-step
                    instructions to enroll in{" "}
                    <StyledLink
                      onClick={() =>
                        openDocumentInNewTab(NationalCourseToolkit)
                      }
                    >
                      National Kyah's EPICourse for Schools
                    </StyledLink>{" "}
                    and show the stock epinephrine laws per each state. Contact
                    your state department of health and/or department of
                    education for more information.
                  </BodyText>

                  <BodyText>
                    <StyledBold>
                      <StyledSpan color="red">
                        Are you from an Arizona school?
                      </StyledSpan>
                    </StyledBold>{" "}
                    Please go to{" "}
                    <StyledLink
                      onClick={() =>
                        goToPath(
                          "/trainings/kyahs-epicourse-for-arizona-schools"
                        )
                      }
                    >
                      Kyah's EPICourse for Arizona Schools
                    </StyledLink>
                    .
                  </BodyText>
                </>
              )
            }
          ]}
        />

        <TrainingMaterialsForSchools />
      </PageContainer>
    </>
  );
};

export default KyahsEpiCourseForSchools;
