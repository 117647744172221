import React from "react";
import { siteDarkGreen } from "../../providers/Theme";
import TeamMemberCard from "../../components/TeamMemberCard";
import LisaCohen from "../../assets/strategy-team/lisa-cohen.jpg";
import AmadaBurke from "../../assets/strategy-team/amanda-burke.jpg";
import KristinaFloor from "../../assets/strategy-team/floor-kris.jpg";
import DavidHanig from "../../assets/strategy-team/david-hanig.jpg";
import MichelleNotrica from "../../assets/strategy-team/michelle-notrica.jpg";
import KimberlyIvich from "../../assets/strategy-team/kimberly-ivich.jpg";
import DanaPaschke from "../../assets/strategy-team/dana-paschke.jpg";
import BrentYap from "../../assets/strategy-team/brent-yap.jpeg";
import { PageContainer, Header } from "../shared.styled.components";

const StrategyTeam: React.FC = () => {
  return (
    <PageContainer>
      <Header color={siteDarkGreen}>Strategy Team</Header>

      <TeamMemberCard
        src={LisaCohen}
        title="Lisa Cohen"
        subTitle="Founder"
        content="Lisa Cohen has cultivated a unique career by combining her passion for movement, communication and adventure. Her early marketing and public relations career led her to Europe and the Middle East working for Reebok and Meeting Planners International. She is a Master Yoga Teacher with over 30 years of experience. She leads Yoga Teacher Trainings and mentorship programs in her home community and around the world. As the Founder of the Kyah Rayne Foundation she is transforming the culture around food allergies. As Kyah's mother, her mission is both personal and political. Committed to revamping education and initiating policy change, Lisa's life work is inspired by and honors the memory of her daughter Kyah. "
      />

      <TeamMemberCard
        src={AmadaBurke}
        title="Dr. Amanda Burke"
        content="Amanda joined the Center for the Future of Arizona (CFA) in 2009 and is the Managing Director, Strategic Initiatives and Impact. Dr. Burke focuses on innovation and systemic change that will advance the priorities of Arizonans and the long-term future success of the state. She oversees CFA's strategic initiatives in the areas of education, workforce, and civic health. Dr. Burke previously served as CFA's Senior Director, Education and Workforce. Prior to joining CFA, she was the Director of Education Innovation and Policy at Arizona State University in the Office of University Initiatives within the Office of the President."
      />

      <TeamMemberCard
        src={KristinaFloor}
        title="Kristina Floor"
        content="Kris is a landscape architect who has focused her 40-year career on creating livable spaces that activate and build community within the urban core. Metrics indicate livable spaces foster social engagement and provide opportunities to connect with the natural environment with a benefit of developing a healthy lifestyle and improving overall well-being. A healthy life begins with a living within a healthy environment. Passionate about making a difference in the food allergy arena, Kris is honored to serve as Secretary for Kyah Rayne Foundation."
      />

      <TeamMemberCard
        src={DavidHanig}
        title="David Hanig"
        content="David was Vice President in state programs for The Lewin Group - a national health care consulting firm. Before Lewin, David Hanig operated a consulting practice in Olympia, Washington, primarily in health care policy. From 2006 - 2012, David worked for the Washington State Democratic Caucus as Senior Policy Analyst for Health and Long‐Term Care Committee. He advised caucus members and drafted legislation, including bills implementing the Affordable Care Act in the state. David worked as a senior manager for 15 years for the state Medicaid agency. While at the state Medicaid agency, Hanig oversaw implementation of program expansions as well as eligibility policy, a 50-FTE customer contact center, and coordination of benefits. Now retired, Hanig continues to serve on non-profit Boards and is committed to the improvement of health care access and quality."
      />

      <TeamMemberCard
        src={KimberlyIvich}
        title="Kimberly Ivich, BSN, RN"
        content="Kimberly is a Public Health Nurse with Maricopa County Department of Public Health and the office of Community Wellness and Health Promotions. She is a leading proponent of the School Surveillance Medication Program and has spent the last two years implementing and certifying this public health program."
      />

      <TeamMemberCard
        src={MichelleNotrica}
        title="Michelle Notrica"
        content="Michelle is a Doctor of Pharmacy, an attorney and a public health consultant in Phoenix, Arizona with an emphasis in International Health and Health Policy. She has special interests in the global burden of social isolation, surgical disease, and the pharmaceutical crisis and the opioid epidemic in developing and developed countries. Michelle currently serves as Executive Director of Arizona Trauma Association.  She has served on several Boards, including Global Surgical Consortium. Dr. Notrica received a Bachelor of Arts degree in Psychology from Emory University, her Pharm.D. from Mercer University, her J.D. from Arizona State University, and her Master's in Public Health from Johns Hopkins Bloomberg School of Public Health."
      />

      <TeamMemberCard
        src={DanaPaschke}
        title="Dana Paschke"
        content="Dana has nearly 20 years of experience in legislative and government relations, organizational development, strategic planning, economic development and community relations. For more than a decade, Dana has been a state legislative lobbyist and consultant for a number of public and private clients. Prior to working in the private sector, Dana spent eight years implementing the intergovernmental program for the City of Glendale (Arizona). A native Arizonan, Dana holds a Bachelor's Degree in Political Science from Texas Christian University and a Master's Degree in Public Administration from Arizona State University."
      />

      <TeamMemberCard
        src={BrentYap}
        title="Brent Yap"
        content="Brent is a father to a child with a severe peanut / tree nut allergy, which has become his drive to help advance KRF's mission. He understands the weight first hand, that comes with entrusting both school and restaurant staff when it comes to the education, training and protocols such as eliminating the potential of cross contamination. Professionally, Brent is an Executive Director at JPMorganChase with nearly two decades of commercial banking experience. Prior to joining JPMorganChase in 2012, he worked in Bank of America Merrill Lynch's Global Commercial Banking group. Brent is U.S. Marine veteran, holding a bachelor degree in business management, and MBA in finance and accounting, as well as the Certified Treasury Professional (CTP®) designation."
      />
    </PageContainer>
  );
};

export default StrategyTeam;
