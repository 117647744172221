import React from "react";
import {
  PageContainer,
  SubHeader,
  BodyText,
  StyledJumbotron,
  JumbotronSubHeader
} from "../shared.styled.components";
import { StyledContainer } from "./styled.components";
import { siteDarkBlue, siteDarkGreen, sitePurple } from "../../providers/Theme";
import DonationLevel from "../../components/DonationLevel";
import Address from "../../components/Address";
import Container from "react-bootstrap/Container";
import DonateButton from "../../components/DonateButton";

const Donate: React.FC = () => {
  return (
    <PageContainer>
      <StyledJumbotron backgroundColor="white" className="pb-0 mb-2">
        <StyledContainer className="p-4">
          <SubHeader>We are all in this together!</SubHeader>

          <BodyText>
            Support us in advancing education and policy to protect the 85M
            American food allergy sufferers and eliminate food allergy deaths.
            Your donations help us provide free food allergy education to
            schools and food service establishments to protect people with food
            allergies.
          </BodyText>
        </StyledContainer>
      </StyledJumbotron>

      <StyledJumbotron backgroundColor="white" className="pb-0 mb-2">
        <StyledContainer className="p-4">
          <SubHeader>How you can help save lives</SubHeader>

          <BodyText>
            Nobody else needs to die. At the Kyah Rayne Foundation, every dollar
            donated plays a pivotal role in food allergy education and creating
            a brighter future for those living with food allergies. As a
            non-profit organization committed to saving lives, we channel your
            generous contributions towards a range of crucial initiatives that
            align with advancing food allergy awareness, education and
            increasing Epinephrine Auto-Injector availability. Thank you for
            being a driving force in our journey!
          </BodyText>
        </StyledContainer>
      </StyledJumbotron>

      <StyledJumbotron backgroundColor="white" className="pb-0 mb-2">
        <StyledContainer className="p-4">
          <SubHeader className="pb-3">Giving Levels</SubHeader>

          <Container>
            <DonationLevel
              title="Allergy Ally"
              amount="100"
              color={siteDarkBlue}
              body={
                <>
                  Donation will sponsor an <strong>outreach team intern</strong>{" "}
                  to contact schools and food service establishments across
                  Arizona.
                </>
              }
            />

            <DonationLevel
              title="Allergy Advocate"
              amount="250"
              color={sitePurple}
              body={
                <>
                  Donation will sponsor one{" "}
                  <strong>development or policy team intern</strong> to work on
                  National EPICourses, grant applications and researching food
                  allergy policy for advocacy.
                </>
              }
            />

            <DonationLevel
              title="Allergy Angel"
              amount="500"
              color={siteDarkGreen}
              body={
                <>
                  Donations will sponsor Kyah's EPICourses at no cost to the
                  user and promote our{" "}
                  <strong>
                    new learning platform for Kyah's EPICourses for Food Service
                  </strong>
                  .
                </>
              }
            />
          </Container>
        </StyledContainer>
      </StyledJumbotron>

      <StyledJumbotron backgroundColor="white" className="pb-0 mb-2">
        <StyledContainer className="p-4">
          <SubHeader className="pb-3">Ways to Give</SubHeader>

          <DonateButton />

          <JumbotronSubHeader className="pt-4">
            Donations can be mailed to:
          </JumbotronSubHeader>

          <Address />
        </StyledContainer>
      </StyledJumbotron>
    </PageContainer>
  );
};

export default Donate;
