import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { sitePurple, siteGray } from "../../providers/Theme";

export const StyledContainer = styled(Container)`
  ${({ theme }) => theme.xs`
    padding-top: 1.5rem;
    max-width: 760px;
  `}

  ${({ theme }) => theme.xl`
    padding-top: 0;
    max-width: 100%;
  `}
`;

export const StyledRow = styled(Row)`
  background-color: ${siteGray};
  margin-top: 1.5rem;
`;

export const StyledCol = styled(Col)`
  padding-left: 0;
  padding-right: 0;
`;

export const BannerTextWrapper = styled.div`
  padding-top: 1.5rem;
  ${({ theme }) => theme.xs`
    padding: 0;
  `}
`;

interface StyledLinkProps {
  color?: string;
}

export const StyledLink = styled.a<StyledLinkProps>`
  font-weight: bold;
  color: ${props => props.color ?? sitePurple};
  text-decoration: underline;

  &:hover {
    color: ${props => props.color ?? sitePurple};
    cursor: pointer;
  }
`;
