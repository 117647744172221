import React from "react";
import DonorCard from "../DonorCard";
import Container from "react-bootstrap/Container";
import CardDeck from "react-bootstrap/CardDeck";
import { siteDarkGreen } from "../../providers/Theme";
import { Header } from "../../pages/shared.styled.components";
import DianeAndBruceHalleFoundationLogo from "../../assets/donors/diane-and-bruce-halle.png";
import BHHSLegacyFoundationLogo from "../../assets/donors/bhhs-legacy.png";
import BlueCrossBlueShieldAzLogo from "../../assets/donors/blue-cross-blue-shield.svg";
import RiesterLogo from "../../assets/donors/riester.jpg";
import CommunityFoundationForSouthernAzLogo from "../../assets/donors/community-foundation-for-southern-az.svg";
import VitalystHealthFoundationLogo from "../../assets/donors/vitalyst.svg";
import ArizonaCommunityFoundationLogo from "../../assets/donors/arizona-community-foundation.jpg";
import StrategicValuePartnersLogo from "../../assets/donors/strategic-value-partners.png";
import ToddThomasFoundationLogo from "../../assets/donors/todd-thomas-foundation.jpg";
import ShapiroLawTeamLogo from "../../assets/donors/shapiro-law.jpg";
import HydrateIvBarLogo from "../../assets/donors/hydrate-iv-bar.png";

const Donors: React.FC = () => {
  return (
    <>
      <Container className="text-center my-5">
        <Header color={siteDarkGreen}>Thank You Donors</Header>

        <CardDeck className="mt-5 d-flex flex-wrap justify-content-center">
          <DonorCard
            title="Diane & Bruce Halle Foundation"
            image={DianeAndBruceHalleFoundationLogo}
            link="https://dianeandbrucehallefoundation.org/"
          />
          <DonorCard
            title="BHHS Legacy Foundation"
            image={BHHSLegacyFoundationLogo}
            link="https://bhhslegacy.org/"
          />
          <DonorCard
            title="Blue Cross Blue Shield Arizona"
            image={BlueCrossBlueShieldAzLogo}
            link="https://www.azblue.com/"
          />
          <DonorCard
            title="Riester Foundation"
            image={RiesterLogo}
            link="https://riester.com/"
          />
          <DonorCard
            title="Community Foundation of Southern Arizona"
            image={CommunityFoundationForSouthernAzLogo}
            link="https://cfsaz.org/"
          />
          <DonorCard
            title="Vitalyst Health Foundation"
            image={VitalystHealthFoundationLogo}
            link="https://vitalysthealth.org/"
          />
          <DonorCard
            title="Arizona Community Foundation"
            image={ArizonaCommunityFoundationLogo}
            link="https://www.azfoundation.org/"
          />
          <DonorCard
            title="SVP Partners"
            image={StrategicValuePartnersLogo}
            link="https://www.svpglobal.com/"
          />
          <DonorCard
            title="Todd Thomas Foundation"
            image={ToddThomasFoundationLogo}
          />
          <DonorCard title="Shapiro Law Firm" image={ShapiroLawTeamLogo} />

          <DonorCard
            title="Hydrate IV Bar"
            image={HydrateIvBarLogo}
            link="https://hydrateivbar.com/locations/arcadia/"
          />
        </CardDeck>
      </Container>
    </>
  );
};

export default Donors;
