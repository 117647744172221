import React from "react";
import {
  BodyText,
  JumbotronSubHeader,
  ListContainer,
  ListItem,
  StyledJumbotron
} from "../../pages/shared.styled.components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { siteDarkBlue, siteGray, sitePurple } from "../../providers/Theme";
import { DiscountLink, StyledLink } from "./styled.components";

interface Discount {
  name: string;
  link: string;
}

interface EpinephrineTypeSectionProps {
  sectionTitle: string;
  imageSrc: string;
  bodyText?: string;
  manufacturer: string;
  availableStrengths: string;
  trainingVideosLink: string;
  discounts: Discount[];
  moreInfoLink: string;
}

const EpinephrineTypeSection: React.FC<EpinephrineTypeSectionProps> = ({
  sectionTitle,
  imageSrc,
  bodyText,
  manufacturer,
  availableStrengths,
  trainingVideosLink,
  discounts,
  moreInfoLink
}) => {
  return (
    <StyledJumbotron className="mb-4" backgroundColor={siteGray} color="black">
      <Container>
        <Row>
          <Col lg={7}>
            <JumbotronSubHeader>{sectionTitle}</JumbotronSubHeader>

            {bodyText && <BodyText>{bodyText}</BodyText>}

            <BodyText className="mt-4 mb-0">
              <b>Manufacturer:</b> {manufacturer}
            </BodyText>
            <BodyText>
              <b>Available Strengths:</b> {availableStrengths}
            </BodyText>

            <StyledLink
              color={sitePurple}
              href={trainingVideosLink}
              target="_blank"
            >
              Training Videos here
            </StyledLink>

            <BodyText className="mt-4 mb-0">
              <b>Discounts</b>
            </BodyText>
            <ListContainer>
              {discounts.map(discount => (
                <ListItem>
                  <DiscountLink href={discount.link} target="_blank">
                    {discount.name}
                  </DiscountLink>
                </ListItem>
              ))}
            </ListContainer>

            <StyledLink
              color={siteDarkBlue}
              href={moreInfoLink}
              target="_blank"
            >
              More Information
            </StyledLink>
          </Col>

          <Col
            lg={5}
            className="d-flex flex-wrap align-items-center justify-content-center"
          >
            <Image fluid src={imageSrc} />
          </Col>
        </Row>
      </Container>
    </StyledJumbotron>
  );
};

export default EpinephrineTypeSection;
