import React from "react";
import {
  BodyText,
  JumbotronSubHeader,
  ListContainer,
  ListItem,
  OrderedListContainer
} from "../../pages/shared.styled.components";

interface FaqListItem {
  text: string;
  children?: string[];
}

interface FaqList {
  listHeader?: string;
  listType?: "ordered" | "unordered";
  listItems: FaqListItem[];
}

interface EpinephrineFAQItemProps {
  title: string;
  bodyText?: string;
  faqList?: FaqList;
  footerElement?: React.ReactElement;
}

const EpinephrineFAQItem: React.FC<EpinephrineFAQItemProps> = ({
  title,
  bodyText,
  faqList,
  footerElement
}) => {
  const ListContainerElement =
    faqList?.listType === "ordered" ? OrderedListContainer : ListContainer;
  return (
    <>
      <JumbotronSubHeader className="mt-4 mb-0">{title}</JumbotronSubHeader>

      {bodyText && <BodyText className="mb-0">{bodyText}</BodyText>}

      {faqList && (
        <>
          {faqList.listHeader && (
            <BodyText className="mb-0">{faqList.listHeader}</BodyText>
          )}

          <ListContainerElement>
            {faqList.listItems.map(item => (
              <>
                <ListItem>{item.text}</ListItem>
                {item.children && (
                  <ListContainer>
                    {item.children.map(child => (
                      <ListItem>{child}</ListItem>
                    ))}
                  </ListContainer>
                )}
              </>
            ))}
          </ListContainerElement>
        </>
      )}

      {footerElement}
    </>
  );
};

export default EpinephrineFAQItem;
