import React from "react";
import KyahsEpiCourseForArizonaSchoolsBanner from "../../assets/kyahs-epicourse-for-arizona-schools-banner.png";
import KyahsEpiCourseForArizonaSchoolsThumbnail from "../../assets/kyah-epicourse-for-arizona-schools.png";
import Image from "react-bootstrap/Image";
import {
  BodyText,
  PageContainer,
  StyledBold,
  StyledSpan,
  StyledVideo,
  SubHeader
} from "../shared.styled.components";
import { siteDarkBlue, siteLightBlue } from "../../providers/Theme";
import JumbotronSplit from "../../components/JumbotronSplit";
import { AZ_EPICOURSE_SCHOOLS_LINK } from "../../constants";
import EpiCourseForSchoolsVideoLibrary from "../../components/EpiCourseForSchoolsVideoLibrary";
import TrainingToolkitsForSchools from "../../components/TrainingToolkitsForSchools";
import SSMPMaricopaCountyToolkitThumbnail from "../../assets/toolkits/ssmp-maricopa-county-toolkit-thumbnail.png";
import SSMPMaricopaCountyToolkit from "../../assets/toolkits/ssmp-maricopa-county-toolkit.pdf";
import { StyledLink } from "./styled.components";
import SSMPPimaCountyToolkitThumbnail from "../../assets/toolkits/ssmp-pima-county-toolkit-thumbnail.png";
import SSMPPimaCountyToolkit from "../../assets/toolkits/ssmp-pima-county-toolkit.pdf";
import TrainingMaterialsForSchools from "../../components/TrainingMaterialsForSchools";
import PageTitleBanner from "../../components/PageTitleBanner";

const openDocumentInNewTab = (file: string) => {
  window.open(file, "_blank");
};

const KyahsEpiCourseForArizonaSchools: React.FC = () => {
  return (
    <>
      <PageTitleBanner
        title="Kyah's EPICourse for Arizona Schools"
        titleTextColor={siteDarkBlue}
        imageSrc={KyahsEpiCourseForArizonaSchoolsBanner}
      />

      <PageContainer>
        <SubHeader className="text-center my-4" color={siteLightBlue}>
          Learn More About Anaphylaxis, Its Causes, and the Proper Emergency
          Response
        </SubHeader>

        <BodyText>
          This section offers valuable learning resources, including videos on
          various topics related to food allergies, as well as downloadable
          graphics to help promote and guide food allergy response. These
          resources are designed to equip individuals with the knowledge needed
          to manage food allergies and respond effectively during an emergency.
        </BodyText>

        <BodyText>
          We invite everyone to take advantage of our free food allergy
          education courses. These courses provide important information on
          managing food allergies and responding to emergencies.
        </BodyText>

        <BodyText>
          <StyledBold>For school personnel outside of Arizona</StyledBold>, we
          recommend enrolling in our National Kyah's EPICourse for Schools. This
          course offers comprehensive, state-specific information on stock
          epinephrine laws and procedures, ensuring that you are equipped with
          the knowledge necessary to handle food allergy emergencies according
          to your state's regulations.
        </BodyText>
      </PageContainer>

      <JumbotronSplit
        title="Register for Kyah's EPICourse for Arizona Schools"
        description="Kyah's EPICourse for Arizona Schools has been specifically created to educate Arizona school staff on the Maricopa County Department of Public Health School Surveillance Medication Program and the Pima County Health Department Stock Medication Program. These programs play a crucial role in ensuring that schools are well-prepared to handle anaphylaxis emergencies, and Kyah's course provides essential training to help school staff understand the appropriate use of stock medications and other vital protocols."
        bgColor={siteLightBlue}
        section1={{
          title: "Kyah's EPICourse for Arizona Schools",
          buttonOptions: {
            text: "Register Now",
            link: AZ_EPICOURSE_SCHOOLS_LINK
          },
          extraContent: (
            <Image fluid src={KyahsEpiCourseForArizonaSchoolsThumbnail} />
          )
        }}
        section2={{
          title: "Easy Enrollment Tutorial Video",
          extraContent: (
            <StyledVideo
              style={{ minHeight: "215px" }}
              src="https://player.vimeo.com/video/969100868?h=1e9260e284"
            />
          )
        }}
      />

      <PageContainer>
        <EpiCourseForSchoolsVideoLibrary />

        <TrainingToolkitsForSchools
          header="Kyah's EPICourse for Arizona Schools Toolkits"
          toolkits={[
            {
              title: "Kyah's EPICourse for Arizona Schools Toolkit",
              subTitle:
                "Maricopa County Department of Public Health School Surveillance Medication Program",

              file: SSMPMaricopaCountyToolkit,
              thumbnail: SSMPMaricopaCountyToolkitThumbnail,
              body: (
                <>
                  <BodyText>
                    The Maricopa County toolkit is designed to show the
                    step-by-step instructions to enroll in the{" "}
                    <StyledLink
                      onClick={() =>
                        openDocumentInNewTab(SSMPMaricopaCountyToolkit)
                      }
                    >
                      Maricopa County Department of Public Health's School
                      Surveillance Stock Medication Program
                    </StyledLink>
                    .
                  </BodyText>

                  <BodyText>
                    <StyledBold>
                      All Arizona schools{" "}
                      <StyledSpan color="red">
                        except those in Pima County
                      </StyledSpan>
                    </StyledBold>{" "}
                    should utilize this toolkit and program.{" "}
                    <StyledBold>Pima County schools</StyledBold> - please see
                    the Pima County toolkit below.
                  </BodyText>
                </>
              )
            },
            {
              title: "Kyah's EPICourse for Arizona Schools Toolkit",
              subTitle:
                "Pima County Health Department Stock Medication Program",
              file: SSMPPimaCountyToolkit,
              thumbnail: SSMPPimaCountyToolkitThumbnail,
              body: (
                <>
                  <BodyText>
                    The Pima County toolkit is designed to show the step-by-step
                    instructions to enroll in the{" "}
                    <StyledLink
                      onClick={() =>
                        openDocumentInNewTab(SSMPPimaCountyToolkit)
                      }
                    >
                      Pima County Health Department's Stock Medication Program.
                    </StyledLink>
                  </BodyText>

                  <BodyText>
                    <StyledBold>Only Arizona schools in Pima County</StyledBold>{" "}
                    should utilize this toolkit and program.{" "}
                    <StyledBold>All other schools</StyledBold> - please see the
                    Maricopa County toolkit above.
                  </BodyText>
                </>
              )
            }
          ]}
        />

        <TrainingMaterialsForSchools />
      </PageContainer>
    </>
  );
};

export default KyahsEpiCourseForArizonaSchools;
