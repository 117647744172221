import styled from "styled-components";

export const VideoContainer = styled.div.attrs({
  className: "embed-responsive embed-responsive-16by9 mx-auto"
})`
  top: 0;
  left: 0;
  clip-path: inset(2px 2px);
  ${({ theme }) => theme.sm`
      width: 75%;
   `}
`;
